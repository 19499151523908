import Table from "@/widgets/Landing/shared/StartMiningWith/components/Table";
import JoinButtonBTC from '@/widgets/Landing/btc/components/JoinButton'
import JoinButton from '@/widgets/Landing/shared/JoinButton'
import { animated } from 'react-spring';
import { useBlockAnimate } from '@/shared/hooks/useBlockAnimate';
import { useNavigation } from '@/processes/Router';
import s from "./StartMiningWith.module.scss";
import {FormattedMessage, useIntl} from "react-intl";
import cx from "classnames";

type PoolListProps = {
  className?: string;
  titleKey?: string;
}

const StartMiningWith = (props: PoolListProps) => {
  const intl = useIntl();
  const { 
    className,
    titleKey = 'landing.startMiningWith.title',
  } = props;
  const { coin, coinTitle, isAccounting } = useNavigation();
  const { ref: ref1, springStyles: springTitleStyles1 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref2, springStyles: springTitleStyles2 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });

  return (
    <div className={cx(s.root, className)}>
      <span ref={ref1} className={cx(s.light, s.abstractLight1, '-top-2/4 -left-36 md:-top-48 md:-left-1/3')} aria-hidden={true}/>
      <span ref={ref2} className={cx(s.light, s.abstractLight2, '-top-1/4 -right-28 md:top-8 md:-right-1/4')} aria-hidden={true}/>

      <div className="flex flex-col md:flex-row items-center mb-12 md:mb-20">
        <div className="mr-4" ref={ref1}>
          <animated.h2 className="landing-title" style={springTitleStyles1}>
            <FormattedMessage id={titleKey} values={{
              coin,
              coinTitle,
              span: (...chunks) => <span className="theme-text-highlight">{chunks}</span>,
            }}/>
          </animated.h2>
        </div>
        <div className="md:w-1/3 mt-12 md:mt-0 text-center">
          {isAccounting ? (
            <JoinButtonBTC title={intl.formatMessage({ id: 'landing.startMiningWith.joinButton' }, { coin })} />
          ) : (
            <JoinButton title={intl.formatMessage({ id: 'landing.startMiningWith.joinButton' }, { coin })} />
          )}
        </div>
      </div>

      <animated.div ref={ref2} style={springTitleStyles2}>
        <Table className="mb-12 sm:mb-36" />
      </animated.div>
    </div>
  )}

export default StartMiningWith;
