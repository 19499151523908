import { useState } from 'react';
import { useTimeoutFn, useCopyToClipboard } from 'react-use';
import _get from 'lodash/get';
import cx from 'classnames';
import Button from '@/shared/ui/Button';

import { IconCopy, IconCheckmark } from '@/shared/ui/Icons';

const RESET_TIMEOUT = 1000;

type CopyProps = {
  text: string;
  label?: string;
  className?: string;
  iconClassName?: string;
  onCopyCallback?: any;
  [x:string]: any;
};

const Copy = (props: CopyProps) => {
  const {
    text = '',
    label = '',
    className = '',
    iconClassName = 'w-[14px] h-[14px]',
    onCopyCallback,
    ...restProps
  } = props;

  const [copied, setCopied] = useState(false);
  const [_state, copyToClipboard] = useCopyToClipboard();
  const resetStatus = () => {
    setCopied(false);
  }

  const [_isReady, _cancel, reset] = useTimeoutFn(resetStatus, RESET_TIMEOUT);

  const handleCopy = () => {
    copyToClipboard(text);
    reset();
    setCopied(true);
    if (typeof onCopyCallback === 'function') {
      onCopyCallback(text);
    }
  }

  return (
    <Button 
      onClick={handleCopy} 
      type="button"
      colorSchema='plain'
      size='xs'
      className={cx(className, {
        'flex items-center': !!label,
      })} 
      {...restProps}
    >
      {copied 
        ? <IconCheckmark className={iconClassName}  />
        : <IconCopy className={iconClassName} />
      }
      {
        label ? (
          <span className={cx('ml-1')}>{label}</span>
        ) : null
      }
    </Button>
  );
}

export default Copy;
