import PropTypes from 'prop-types';
import Link from 'next/link';
import * as s from './FooterSocials.module.scss';

const FooterSocials = (props) => {
  const {
    socials,
  } = props;

  return (
    <div className={s.root}>
      <div className={s.filler}/>
      <div className={s.social}>
        {socials.map(({ title, url, Icon, gtmParams = {} }) => {
          return (
            <Link key={url} className={s.socialItem} href={url} title={title} {...gtmParams} target="_blank">
              <Icon />
            </Link>
          )
        })}
      </div>
    </div>
  );
}

FooterSocials.propTypes = {
  socials: PropTypes.array,
}

FooterSocials.defaultProps = {
  socials: [],
};

export default FooterSocials;
