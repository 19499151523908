import { FormattedMessage } from "react-intl";
import cx from "classnames";
import s from "./Faq.module.scss";
import Accordion, { AccordionTrigger, AccordionContent, AccordionItem } from "@/shared/ui/Accordion";
import { IconWarning } from "@/shared/ui/Icons";

type FaqProps = {
  className?: string;
}

const Faq = (props: FaqProps) => {
  const { className} = props;

  return (
    <div className={cx(s.root, className)}>
      <h2 className="text-4/5xl text-center sm:text-left sm:text-8xl font-bold my-5 sm:mt-0">
        <FormattedMessage id="faq" />
      </h2>
      <Accordion>
        <AccordionItem value="item-1">
          <AccordionTrigger className={s.trigger}>
            <FormattedMessage id="landing.dnx.faq.trigger.1" />
          </AccordionTrigger>
          <AccordionContent className={s.content}>
            <FormattedMessage id="landing.dnx.faq.content.1" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-2">
          <AccordionTrigger className={s.trigger}>
            <FormattedMessage id="landing.dnx.faq.trigger.2" />
          </AccordionTrigger>
          <AccordionContent className={s.content}>
            <FormattedMessage id="landing.dnx.faq.content.2" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-3">
          <AccordionTrigger className={s.trigger}>
            <FormattedMessage id="landing.dnx.faq.trigger.3" />
          </AccordionTrigger>
          <AccordionContent className={s.content}>
            <FormattedMessage id="landing.dnx.faq.content.3" values={{
              a: (...chunks) => (
                <a href="https://www.hashrate.no/coins/DNX" target="_blank" className="text-dynex">{chunks}</a>
              )
            }}/>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-4">
          <AccordionTrigger className={s.trigger}>
            <FormattedMessage id="landing.dnx.faq.trigger.4" />
          </AccordionTrigger>
          <AccordionContent className={s.content}>
            <FormattedMessage id="landing.dnx.faq.content.4" />
            <ol className={s.decimalList}>
              <li>
                <FormattedMessage id="landing.dnx.faq.content.4.list.item.1" values={{
                  a: (...chunks) => (
                    <a href="https://hiveon.com/os/" target="_blank" className="text-dynex">{chunks}</a>
                  )
                }}/>
              </li>
              <li>
                <FormattedMessage id="landing.dnx.faq.content.4.list.item.2" values={{
                  a: (...chunks) => (
                    <a href="https://github.com/dynexcoin/Dynex-Wallet-App/releases" target="_blank" className="text-dynex">{chunks}</a>
                  ),
                  a2: (...chunks) => (
                    <a href="https://dynexwallet.dynexcoin.org/public_html/signup2.php" target="_blank" className="text-dynex">{chunks}</a>
                  )
                }}/>

                <div className={s.warning}>
                  <IconWarning className="mr-4 flex-shrink-0 w-6 h-6"/>
                  <FormattedMessage id="landing.dnx.faq.content.4.list.item.2.warning"/>
                </div>
              </li>
              <li><FormattedMessage id="landing.dnx.faq.content.4.list.item.3"/></li>
              <li><FormattedMessage id="landing.dnx.faq.content.4.list.item.4"/></li>
              <li><FormattedMessage id="landing.dnx.faq.content.4.list.item.5"/></li>
            </ol>
            <FormattedMessage id="landing.dnx.faq.content.4.additional" values={{
              a: (...chunks) => (
                <a href="https://hiveon.com/knowledge-base/" target="_blank" className="text-dynex">{chunks}</a>
              )
            }}/>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-5">
          <AccordionTrigger className={s.trigger}>
            <FormattedMessage id="landing.dnx.faq.trigger.5" />
          </AccordionTrigger>
          <AccordionContent className={s.content}>
            <FormattedMessage id="landing.dnx.faq.content.5" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-6">
          <AccordionTrigger className={s.trigger}>
            <FormattedMessage id="landing.dnx.faq.trigger.6" />
          </AccordionTrigger>
          <AccordionContent className={s.content}>
            <FormattedMessage id="landing.dnx.faq.content.6" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-7">
          <AccordionTrigger className={s.trigger}>
            <FormattedMessage id="landing.dnx.faq.trigger.7" />
          </AccordionTrigger>
          <AccordionContent className={s.content}>
            <FormattedMessage id="landing.dnx.faq.content.7" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-8">
          <AccordionTrigger className={s.trigger}>
            <FormattedMessage id="landing.dnx.faq.trigger.8" />
          </AccordionTrigger>
          <AccordionContent className={s.content}>
            <FormattedMessage id="landing.dnx.faq.content.8" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-8">
          <AccordionTrigger className={s.trigger}>
            <FormattedMessage id="landing.dnx.faq.trigger.9" />
          </AccordionTrigger>
          <AccordionContent className={s.content}>
            <FormattedMessage id="landing.dnx.faq.content.9" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-9">
          <AccordionTrigger className={s.trigger}>
            <FormattedMessage id="landing.dnx.faq.trigger.10" />
          </AccordionTrigger>
          <AccordionContent className={s.content}>
            <FormattedMessage id="landing.dnx.faq.content.10" values={{ b: (...chunks) => (<b>{chunks}</b>) }}/>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-10">
          <AccordionTrigger className={s.trigger}>
            <FormattedMessage id="landing.dnx.faq.trigger.11" />
          </AccordionTrigger>
          <AccordionContent className={s.content}>
            <FormattedMessage id="landing.dnx.faq.content.11" />
          </AccordionContent>
        </AccordionItem>


      </Accordion>
  </div>
  );
};

export default Faq;