import React from "react";
import { FormattedMessage } from "react-intl";
import cx from "classnames";
import _get from "lodash/get";
import s from "./Advantages.module.scss";
import { animated } from 'react-spring';
import { useBlockAnimate } from '@/shared/hooks/useBlockAnimate';
import { useNavigation } from '@/processes/Router';

type AdvantagesProps = {
  className?: string;
}

const Advantages = (props: AdvantagesProps) => {
  const { className } = props;
  const { coin } = useNavigation();

  const { ref: ref1, springStyles: springTitleStyles1 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref2, springStyles: springTitleStyles2 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref4, springStyles: springTitleStyles4 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref6, springStyles: springTitleStyles6 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });

  return (
    <div className={cx(s.root, className)} ref={ref1}>
      <div className={s.blocks}>
        <span className={cx(s.abstract1, '-left-80 -bottom-48')} aria-hidden={true}/>
        <span className={cx(s.abstract1, '-right-52 -top-48')} aria-hidden={true}/>
        
        <span className={cx(s.abstract2, '-top-10 left-0 right-0 mx-auto')} aria-hidden={true}/>
        <span className={cx(s.abstract2, '-bottom-10 left-0 right-0 mx-auto transform rotate-180')} aria-hidden={true}/>
        <span className={cx(s.abstract2, '-left-44 -ml-2.5 top-0 bottom-0 my-auto transform -rotate-90')} aria-hidden={true}/>
        <span className={cx(s.abstract3, '-right-2 -mr-2.5 -top-56 bottom-0 my-auto transform')} aria-hidden={true}/>
       
        <animated.div ref={ref2} style={springTitleStyles2} className={s.blocksGrid}>
          <div className={s.blocksCol}>
            <div className={cx(s.blockWrap, s.blockmH208)}>
              <div className={cx(s.block, s.blockBg1)}>
                <div className="flex gap-3 align-top">
                  <span className={cx("theme-text-highlight shadow font-bold text-3xl sm:text-5xl lg:text-[116px] lg:leading-[0.9]")}>
                    0%
                  </span>

                  <div className="flex flex-col">
                    <span
                      className={cx("theme-text-highlight shadow text-3xl sm:text-5xl font-bold break-all")}>
                      <FormattedMessage id="landing.advantages.fee.title" />{' '}
                    </span>
                    <span className="font-semibold mt-4 text-xl sm:text-2xl">
                      <FormattedMessage id="landing.dnx.advantages.fee.desc"/>
                    </span>
                  </div>
                </div>
              </div>
              <div className={cx(s.blockHover, s.blockHoverPos3)}>
                <FormattedMessage id="landing.advantages.fee.hover" values={{coin}}/>
              </div>
            </div>
          </div>

          <div className={s.blocksCol}>
            <div className={cx(s.blockWrap, s.blockmH126)}>
              <div className={cx(s.block, s.blockBg3, s.blockRow, 'gap-4 items-center')}>
                <span className={cx("theme-text-highlight shadow font-bold text-3xl sm:text-5xl" )}>
                  <FormattedMessage id="landing.dnx.advantages.PPLNS.title" />
                </span>
                <span className={cx("font-semibold text-xl sm:text-2xl")}>
                  <FormattedMessage id="rewardType.text" />
                </span>
              </div>

              <div className={cx(s.blockHover, s.blockHoverPos2)}>
                <FormattedMessage id="landing.advantages.fee.hover" values={{ coin }}/>
              </div>
            </div>
          </div>

          <div className={s.blocksCol}>
            <div className={cx(s.blockWrap, s.blockmH126)}>
              <div className={cx(s.block, s.blockBg5)}>
                <span className={cx("theme-text-highlight shadow font-bold text-3xl sm:text-5xl" )}>
                  <FormattedMessage id="landing.support.title" />
                </span>
              </div>
            </div>
          </div>
        </animated.div>

        <animated.div ref={ref4} style={springTitleStyles4} className={s.blocksGrid}>
          <div className={s.blocksCol}>
            <div className={cx(s.blockWrap, s.blockmH126)}>
              <div className={cx(s.block, s.blockBg2)}>
                <span
                  className={cx(
                    "theme-text-highlight hover-light shadow font-bold text-3xl sm:text-5xl"
                  )}
                >
                  <FormattedMessage id="landing.advantages.percent.poolFee" values={{percent: "1%"}} />
                </span>
              </div>
              <div className={cx(s.blockHover, s.blockHoverPos2)}>
                <FormattedMessage id="landing.kas.advantages.fee.hover" />
              </div>
            </div>
          </div>

          <div className={s.blocksCol}>
            <div className={cx(s.blockWrap, s.blockmH208)}>
              <div className={cx(s.block, s.blockBg4)}>
                <span
                  className={cx(
                    "theme-text-highlight shadow text-3xl sm:text-5xl font-bold"
                  )}
                >
                  <FormattedMessage id="landing.kas.advantages.free_os.title" />
                </span>
                <span className="font-semibold mt-4 text-xl sm:text-2xl">
                  <FormattedMessage id="landing.kas.advantages.free_os.desc" values={{ coin }}/>
                </span>
              </div>
              <div className={cx(s.blockHover, s.blockHoverPos1)}>
                <FormattedMessage id="landing.kas.advantages.free_os.hover" />
              </div>
            </div>
          </div>

          <div className={s.blocksCol}>
            <div className={cx(s.blockWrap, s.blockmH126)}>
              <div className={cx(s.block, s.blockBg6, s.blockRow, 'gap-4 items-center')}>
                <span
                  className={cx(
                    "theme-text-highlight shadow font-bold text-3xl sm:text-5xl"
                  )}
                >
                  1 {' '} <span className="upercase">{coin}</span>
                </span>
                <span className={cx("font-semibold text-xl sm:text-2xl")}>
                  <FormattedMessage id="landing.dnx.advantages.payouts.desc" />
                </span>
              </div>
            </div>
          </div>
        </animated.div>
      </div>

      <animated.p
        className="text-base sm:text-xl my-4 sm:my-8 leading-6 max-w-[850px] mx-auto"
        style={springTitleStyles6}
        ref={ref6}
      >
        <FormattedMessage id={"landing.dnx.advantages.desc"}/>
      </animated.p>
    </div>
  );
};

export default Advantages;
