import React, { useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from "react-intl";
import s from './RecentlySearched.module.scss';
import CoinIcon from "@/shared/ui/CoinIcon";
import { IconArrowTo } from "@/shared/ui/Icons";
import isTouchDevice from '@/shared/utils/isTouchDevice';
import { trimAddress } from "@/shared/utils/formatters";
import {
  addToRecentSearches,
  useMinerAccount,
} from "@/processes/MinerAccounts";
import { useNavigation } from '@/processes/Router';
import useBreakpoint from "@/shared/hooks/useBreakpoint";

type SelectSubaccountProps = {
  className?: string;
  onKeyDown?: (e: any) => void;
}

const RecentlySearched = (props: SelectSubaccountProps) => {
  const {
    className = '',
    onKeyDown
  } = props;
  const dispatch = useDispatch();
  const { updateURLQueryParams, coin, isSupportSubAccount, getPoolDashboardLink, navigate } = useNavigation();
  const { isMinMediaSm, isMinMediaXs } = useBreakpoint()

  const {
    account,
    isSupport,
    isAccounting,
    recentSearches,
  } = useMinerAccount();

  const handleMinerAddressSelect = ({ coin, address, isSearch = false }: any) => {
    // save to recent searches
    if (isSearch) {
      dispatch(addToRecentSearches({ coin, address }));
    }
    // navigate to dashboard
    const dashboardUrl = getPoolDashboardLink({ miner: address, coin });
    navigate(dashboardUrl);
  };

  useEffect(() => {
    if (account && isAccounting) {
      updateURLQueryParams({ account, support: isSupport });
    }
  }, [account, isSupport, isAccounting]); // eslint-disable-line

  if (recentSearches.length === 0) return null

  return (
    <div className={cx(s.list, className)} onKeyDown={onKeyDown}>
      <div className={s.listContainer}>
        <div className={cx(s.listTitle)}><FormattedMessage id="header.subaccount.select.recenlty.title" /></div>
        {recentSearches.map(({coin, address}: any) => (
          <button
            type="button"
            key={address}
            className={cx(s.item, 'justify-between', {[s.touchDevice]: isTouchDevice()})}
            onClick={() => handleMinerAddressSelect({ coin, address })}
          >
            <span className='flex items-center'>
              <CoinIcon coin={coin} className='h-3 w-3 mr-1'/>
              { isMinMediaSm ? address : trimAddress(address) }
            </span>
            <IconArrowTo className={cx(s.arrowTo, "ml-1 w-4")}/>
          </button>
        ))}
      </div>
    </div>
  );
}

export default RecentlySearched;
