import React from "react";
import { FormattedMessage } from "react-intl";
import cx from "classnames";
import s from "./BannerContent.module.scss";
import AddressExplorer from "@/widgets/Landing/shared/AddressExplorer";
import ThemeButton from "@/shared/ui/ThemeButton";
import {useNavigation} from "@/processes/Router";

type AdvantagesProps = {
  isAuth?: any;
}

const BannerContent = (props: AdvantagesProps) => {
  const { isAuth } = props;
  const { navigate, poolDashboardLink } = useNavigation();
  return (
    <div className={cx(s.root)}>
      <div className={s.content}>

        <h4 className={cx(s.title, s.titleBig)}>
          Kaspa Pool is terminated
        </h4>

        <h4 className={s.title}>
          All mined coins were paid out to the wallets indicated in your Flight Sheets.
        </h4>

        <h4 className={s.title}>
          See you in Hiveon OS 🧡
        </h4>

        {/* {!isAuth ? (
          <AddressExplorer className={'mt-3'}/>
        ) : <ThemeButton className={'mt-3'} onClick={() => {
          navigate(poolDashboardLink);
        }}><FormattedMessage id="dashboard" /></ThemeButton>} */}

      </div>
    </div>
  );
};

export default BannerContent;
