import { useRouter } from 'next/router';
import { LOCALIZED_LINKS } from '@/shared/constants/localizedLinks';
import _get from 'lodash/get';

export const useLocalizedUrl = () => {
  const router = useRouter();
  const locale = router.locale || 'en';

  return {
    localizeUrl: (url: string) => {
      const intlUrl = _get(LOCALIZED_LINKS, [url, locale], null);
      return intlUrl || url;
    },
  }
};
