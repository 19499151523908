import { LINK_TYPES, DOMAIN_TYPES } from '~/Footer/constants/footer';
import { Facebook, Telegram, Youtube, Discord, Twitter } from './FooterSocials/icons'

export const FOOTER_NAV = {
  'hiveOS': {
    title: 'footer.os',
    items: [
      {
        title: 'footer.os.gs',
        url: 'https://hiveon.com/install',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/install/',
          zh: 'https://hiveon.com/zh/install/',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.os.why',
        url: 'https://hiveon.com/features',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/features/',
          zh: 'https://hiveon.com/zh/features/',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.os.pricing',
        url: 'https://hiveon.com/pricing',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/pricing/',
          zh: 'https://hiveon.com/zh/pricing/',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.os.referral',
        url: 'https://hiveon.com/referral',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/referral/',
          zh: 'https://hiveon.com/zh/referral/',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.os.contacts',
        url: 'https://hiveon.com/contact',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/contact/',
          zh: 'https://hiveon.com/zh/contact/',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.os.api',
        url: 'https://hiveon.com/forum/t/hive-api-v2/4490',
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.os.terms',
        url: 'https://hiveon.com/terms',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/terms/',
          zh: 'https://hiveon.com/zh/terms/',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.os.vulnerability',
        url: 'https://hiveon.com/vulnerability-disclosure-policy/',
        urlsIntl: {
          zh: 'https://hiveon.com/zh/vulnerability-disclosure-policy/',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.os.status',
        url: 'https://status.hiveos.farm/',
        domainType: DOMAIN_TYPES.external,
      },
    ],
  },
  'content': {
    title: 'footer.content',
    items: [
      {
        title: 'Github',
        url: 'https://github.com/minershive',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      {
        title: 'footer.content.log',
        url: 'https://hiveon.com/changelog',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/changelog/',
          zh: 'https://hiveon.com/zh/changelog/',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.content.stats',
        url: 'https://hiveon.com/statistics',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/statistics/',
          zh: 'https://hiveon.com/zh/statistics/',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.content.blog',
        url: 'https://hiveon.com/blog',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/blog/',
          zh: 'https://hiveon.com/zh/blog/',
        },
        domainType: DOMAIN_TYPES.external,
        gtmParams: {
          'data-gtm-category': 'site_footer',
          'data-gtm-action': 'blog_click',
          'data-gtm-label': JSON.stringify({ button: 'blog' }),
        },
      },
    ],
  },
  'mobile': {
    title: 'footer.content.apps',
  },
  'community': {
    title: 'footer.community',
    items: [
      {
        title: 'footer.community.forum',
        url: 'https://hiveon.com/forum/',
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'EN',
        url: 'https://t.me/hiveoschat_en',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      {
        title: '中文',
        url: 'https://t.me/hiveoschat/1542546',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      // {
      //   title: '日本',
      //   url: 'https://t.me/hiveoschat_jp',
      //   domainType: DOMAIN_TYPES.external,
      //   noIntl: true,
      // },
      {
        title: 'FR',
        url: 'https://t.me/hiveoschat_fr',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      {
        title: 'IT',
        url: 'https://t.me/hiveoschat_italia',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      {
        title: 'ES',
        url: 'https://t.me/hiveon_os_es',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      // {
      //   title: 'Discord',
      //   url: 'https://discord.gg/CVZeZdn',
      //   domainType: DOMAIN_TYPES.external,
      //   noIntl: true,
      // },
    ],
  },
  'support': {
    title: 'footer.support',
    items: [
      {
        title: 'bee@hiveos.farm',
        url: 'mailto:bee@hiveos.farm',
        domainType: DOMAIN_TYPES.external,
        linkType: LINK_TYPES.email,
        noIntl: true,
      },
      {
        title: 'footer.support.kb',
        url: 'https://hiveon.com/knowledge-base',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/knowledge-base/',
          zh: 'https://hiveon.com/zh/knowledge-base/',
        },
        domainType: DOMAIN_TYPES.external,
      },
    ],
  },
  'other': {
    title: 'footer.other',
    items: [
      {
        title: 'footer.other.pool',
        url: '/',
        urlsIntl: {
          ru: '/ru/',
          zh: '/zh/',
        },
      },
      {
        title: 'footer.other.faq',
        url: 'https://hiveon.com/knowledge-base/hiveon-pool',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/knowledge-base/hiveon-pool',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.other.rules',
        url: 'https://hiveon.com/knowledge-base/hiveon-pool/pool_rules/',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/knowledge-base/hiveon-pool/pool_rules/',
          zh: 'https://hiveon.com/zh/knowledge-base/hiveon-pool/pool_rules/',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.other.firmware',
        url: 'https://hiveon.com/asic',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/asic/',
          zh: 'https://hiveon.com/zh/asic/',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.other.hub',
        url: 'https://hiveon.com/asichub',
        urlsIntl: {
          ru: 'https://hiveon.com/ru/asichub/',
          zh: 'https://hiveon.com/zh/asichub/',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'footer.other.energy',
        url: 'https://hiveon.com/energy/',
        urlsIntl: {
          zh: 'https://hiveon.com/energy/',
        },
        domainType: DOMAIN_TYPES.external,
      },
    ],
  },
};

export const FOOTER_SOCIALS = [
  {
    title: 'Telegram',
    url: 'https://t.me/hiveon_official',
    Icon: Telegram,
  },
  {
    title: 'Facebook',
    url: 'https://www.facebook.com/hiveon/',
    Icon: Facebook,
  },
  {
    title: 'Twitter',
    url: 'https://twitter.com/hiveonofficial',
    Icon: Twitter,
  },
  {
    title: 'Youtube',
    url: 'https://www.youtube.com/HiveOS',
    Icon: Youtube,
  },
  {
    title: 'Discord',
    url: 'https://discord.gg/CVZeZdn',
    Icon: Discord,
  },
]
