import _get from 'lodash/get';
import {
  ResponsiveContainer,
  Line,
  ComposedChart,
  Area,
  CartesianGrid,
  CartesianAxis,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import ChartTooltip from '@/shared/ui/charts/ChartTooltip';

type AxisType = {
  dataKey?: string;
  interval?: any;
  orientation?: 'left' | 'right';
  domain?: any;
  width?: number;
  formatter?: (value:any) => any;
  tick?: any;
}

type CombinedLineChartProps = {
  data: any[],
  lines: {
    name: string;
    dataKey: string;
    stroke: string;
    dot?: boolean;
    formatter?: (value:any) => any;
    type: 'AREA' | 'LINE';
    fill?: 'primary' | 'secondary' | 'orange';
  }[],
  tooltipValueFormatter?: (...props: any) => any,
  tooltipLabelFormatter?: (value:any) => any,
  XAxisProps: AxisType,
  YAxisProps: AxisType,
  className?: string;
  isLoading?: boolean;
  tooltipLabelColors?: any;
}

const GRADIENT_TYPES = {
  primary: 'chartColorPrimary',
  secondary: 'chartColorSecondary',
  orange: 'chartColorOrange',
}

const CustomCursor = (props: any) => {
  const { height, top } = props;
  const xCoord = _get(props, 'points.0.x', 0);
  const yCoord = top;
  return (
    <g opacity="0.8">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="TooltipLine-gradient">
          <stop stopColor="#00ccaa" offset="0%" />
          <stop stopColor="#00ccaa" stopOpacity={0} offset="99.23753%" />
        </linearGradient>
      </defs>
      <g id="TooltipLine" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="TooltipLine-rect" fill="url(#TooltipLine-gradient)" x={xCoord} y={yCoord} width="1" height={height} />
      </g>
    </g>
  );
};

const CombinedLineChart = (props: CombinedLineChartProps) => {
  const { 
    data = [],
    lines = [],
    tooltipValueFormatter = (value) => value,
    tooltipLabelFormatter = (value) => value,
    tooltipLabelColors = {},
    XAxisProps = {
      dataKey: '',
      formatter: (value) => value,
    },
    YAxisProps = {
      dataKey: '',
      width: 40,
      formatter: (value) => value,
      tick: {},
    },
    className = 'w-full h-[300px] p-5',
    isLoading = false,
  } = props;

  return (
    <div className={className}>
      <ResponsiveContainer>
        <ComposedChart
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: 8,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="chartColorPrimary" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#00ccaa" stopOpacity={0.45}/>
              <stop offset="100%" stopColor="#00ccaa" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="chartColorSecondary" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#7957FF" stopOpacity={0.45}/>
              <stop offset="100%" stopColor="#7957FF" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="chartColorOrange" x1="0" y1="0" x2="0" y2="1">
              <stop offset="20%" stopColor="#ffa801" stopOpacity={0.6} />
              <stop offset="95%" stopColor="#1b1b1b" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#fff" strokeOpacity={0.08}/>
          <CartesianAxis />
          {XAxisProps.dataKey ? (
            <XAxis 
              dataKey={XAxisProps.dataKey}
              tickFormatter={XAxisProps.formatter}
              tick={{fontSize: 12}}
              dy={5}
              dx={0}
            />
          ) : null}
          {YAxisProps.dataKey ? (
            <YAxis 
              type="number"
              dataKey={YAxisProps.dataKey}
              orientation={YAxisProps.orientation || 'right'}
              interval={YAxisProps.interval || {}}
              domain={YAxisProps.domain || [0, 'auto']}
              tickFormatter={YAxisProps.formatter}
              tick={{
                fontSize: 12,
                fontWeight: 300,
                ...YAxisProps.tick
              }}
              dy={0}
              dx={5}
              width={YAxisProps.width || 40}
            />
          ) : null}
          <Tooltip 
            cursor={<CustomCursor />}
            animationDuration={100}
            content={<ChartTooltip colors={tooltipLabelColors}/>}
            labelFormatter={tooltipLabelFormatter}
            formatter={tooltipValueFormatter}
          />
          {
            lines.map((line) => {
              const { type, fill = 'primary', dot = true } = line;
              const gradientType = GRADIENT_TYPES[fill];
              return (
                {
                  'LINE': <Line
                    key={line.name}
                    name={line.name}
                    dataKey={line.dataKey}
                    stroke={line.stroke}
                    strokeWidth={1.5}
                    r={0}
                    dot={dot}
                    activeDot={dot ? { r: 6 } : false}
                  />,
                  'AREA': <Area
                    key={line.name}
                    name={line.name}
                    dataKey={line.dataKey}
                    stroke={line.stroke}
                    type="monotone"
                    strokeWidth={1.5}
                    fill={`url(#${gradientType})`}
                  />,
                }[line.type]
              )
            })
          }
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CombinedLineChart;
