import Support from "@/widgets/Landing/shared/Support";
import Advantages from "@/widgets/Landing/kas/components/Advantages";
import Banner from "@/widgets/Landing/kas/components/Banner";
import BannerContent from "@/widgets/Landing/kas/components/BannerContent";
import StartMiningWith from "@/widgets/Landing/shared/StartMiningWith";
import ProfitCalculator from "~/ProfitCalculator";
import AddressExplorer from "@/widgets/Landing/shared/AddressExplorer";
import QuickStats from "@/widgets/Landing/shared/QuickStats";
import Intro from  "@/widgets/Landing/both/Intro";
import MinerBlocks from "@/widgets/Landing/shared/MinerBlocks";
import JoinSection from "@/widgets/Landing/shared/JoinSection";
import { useMinerAccount } from "@/processes/MinerAccounts";

const LandingBlocks = () => {
  const { isAuth } = useMinerAccount();
  return (
    <>
      <BannerContent isAuth={isAuth} />
      <Banner>
        <Intro className={isAuth ? 'inner py-20 md:py-32' : 'inner pt-20 md:pt-32'} titleKey="l.kas.title" descKey="l.kas.desc"/>

        {/* {!isAuth ? (
          <AddressExplorer className={'inner my-6 sm:my-8'}/>
        ) : null} */}

        {/* <ProfitCalculator className="my-6 sm:my-8 sm:mb-32"/> */}
        {/* <QuickStats className="my-6 sm:my-8" /> */}
        {/* <MinerBlocks className="my-6 md:my-8" /> */}
        <Advantages className='my-20 md:my-40 md:mb-20'/>
        <Support className='my-20 md:my-40 md:mt-20' />
        <StartMiningWith className='my-20 md:my-40' titleKey="landing.startMiningWith.title.v2"/>
        <JoinSection className='pb-8 my-20 md:mb-20 md:mt-40' titleKey="footer.join.title.v2"/>
      </Banner>
    </>
  );
}

export default LandingBlocks;
