import cx from 'classnames';
import s from './TableResult.module.scss';
import { FormattedMessage } from "react-intl";
import { curToSymbol } from "@/shared/utils/formatters";
import CoinIcon from "@/shared/ui/CoinIcon";
import { useNavigation } from "@/processes/Router";
import { toFixed } from "@/shared/utils/toFixed";
import _get from "lodash/get";
import { useCryptoFormatter } from '@/processes/PoolStats';
import { FIAT_DECIMALS } from '~/ProfitCalculator/constants/fiat';

type TableResultProps = {
  tableHrLabel: string;
  currency: string;
  calculated: any;
  exchangeRateUSD: string;
}

const TableResult = (props: TableResultProps) => {
  const {
    tableHrLabel,
    currency,
    calculated,
    exchangeRateUSD
  } = props;

  const { coin, isDNX } = useNavigation();
  const { formatCryptoAmount } = useCryptoFormatter({ coin });
  
  const themeColor = isDNX ? 'theme-gradient-color' : 'theme-color';

  const fiatDayFormat = toFixed(_get(calculated, "day.fiat", 0), FIAT_DECIMALS);
  const fiatMonthFormat = toFixed(_get(calculated, "month.fiat", 0), FIAT_DECIMALS);
  const cryptoDayFormat = formatCryptoAmount(_get(calculated, "day.crypto", 0));
  const cryptoMonthFormat = formatCryptoAmount(_get(calculated, "month.crypto", 0));
  const electricDayFormat = toFixed(_get(calculated, "day.electricCost", 0), FIAT_DECIMALS);
  const electricMonthFormat = toFixed(
    _get(calculated, "month.electricCost", 0),
    2
  );
  const profitDayFormat = toFixed(_get(calculated, "day.profit", 0), FIAT_DECIMALS);
  const profitMonthFormat = toFixed(_get(calculated, "month.profit", 0), FIAT_DECIMALS);

  return (
    <div className={s.results}>
      <table className={s.table}>
        <colgroup>
          <col />
          <col />
          <col />
        </colgroup>

        <thead className={s.tableHead}>
        <tr>
          <th className={themeColor}>
            {tableHrLabel ? (
              <FormattedMessage
                id="landing.profit.calculator.advanced.table.th.perUnits"
                values={{
                  units: tableHrLabel,
                }}
              />
            ) : null}
          </th>
          <th>
            ~ <FormattedMessage id="landing.profit.calculator.advanced.table.th.day" />{" "}
            <span className={themeColor}>*</span>
          </th>
          <th>
            ~ <FormattedMessage id="landing.profit.calculator.advanced.table.th.month" />{" "}
            <span className={themeColor}>*</span>
          </th>
        </tr>
        </thead>

        <tbody className={s.tableBody}>
        <tr>
          <td>
            <FormattedMessage id="landing.profit.calculator.advanced.table.td.estEarnings" />
          </td>
          <td>
            <span className={cx(s.value, s.fiat, themeColor)}>
              {curToSymbol(currency)}
              {fiatDayFormat}
            </span>
            <span className={cx(s.value, s.crypto)}>
              <CoinIcon
                coin={coin}
                className="w-4 h-4"
              />
              {' '}
              <span>
                {cryptoDayFormat} {coin}
              </span>
            </span>
          </td>
          <td>
            <span className={cx(s.value, s.fiat, themeColor)}>
              {curToSymbol(currency)}
              {fiatMonthFormat}
            </span>
            <span className={cx(s.value, s.crypto)}>
              <CoinIcon
                coin={coin}
                className="w-4 h-4"
              />
              {' '}
              <span>
                {cryptoMonthFormat} {coin}
              </span>
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <FormattedMessage id="landing.profit.calculator.advanced.table.td.elCosts" />
          </td>
          <td>
            <span className={cx(s.value)}>
              {curToSymbol(currency)}
              {electricDayFormat}
            </span>
          </td>
          <td>
            <span className={cx(s.value)}>
              {curToSymbol(currency)}
              {electricMonthFormat}
            </span>
          </td>
        </tr>
        <tr className={themeColor}>
          <td>
            <FormattedMessage id="landing.profit.calculator.advanced.table.td.estProfit" />
          </td>
          <td>
            <span className={cx(s.value, themeColor)}>
              {curToSymbol(currency)}
              {profitDayFormat}
            </span>
          </td>
          <td>
            <span className={cx(s.value, themeColor)}>
              {curToSymbol(currency)}
              {profitMonthFormat}
            </span>
          </td>
        </tr>
        </tbody>
      </table>
      <div className={s.note}>
        <span className={themeColor}>*</span>{" "}
        <span>
          <FormattedMessage
            id="landing.profit.calculator.advanced.table.note"
            values={{
              coin,
              rate: exchangeRateUSD,
              currency,
            }}
          />
        </span>
      </div>
    </div>
  );
}

export default TableResult;
