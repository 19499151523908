import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import _get from "lodash/get";
import Box from '@/shared/ui/Box'
import s from './NetworkHashrateChart.module.scss';
import { getFormattedPower } from '@/shared/utils/formatters';
import { formatChartDate, getBoundaryTimestamps } from '@/shared/utils/dateFormatters';
import CombinedLineChart from '@/shared/ui/charts/CombinedLineChart';
import { useCurrency } from '@/shared/hooks/useCurrency';
import { useGetBTCNetworkHashrateQuery } from './api';
import {useSelector} from "react-redux";
import { selectDateRange } from "@/processes/Settings";

type NetworkHashrateChartProps = {
  className: string,
}
const NetworkHashrateChart = (props: NetworkHashrateChartProps) => {
  const {
    className,
  } = props;

  const intl = useIntl();
  const { formatCurrency } = useCurrency();
  const { isSuccess, data = {} } = useGetBTCNetworkHashrateQuery(null);
  const chartData = _get(data, 'chart_data', []);
  const dateRange = useSelector(selectDateRange);

  return (
    <Box className={cx(className)} size='none'>
      <div className="text-sm flex justify-between items-center mb-5 sm:mb-10">
        <span className="flex items-center">
          <span className={cx(s.status)} aria-hidden={true} />
          <FormattedMessage id="Network Hashrate" />
        </span>
        <span className='text-white/[0.6]'>
          <FormattedMessage id="weekly" />
        </span>
      </div>
      <CombinedLineChart
        isLoading={!isSuccess}
        className="w-full h-[266px] mt-6"
        data={chartData}
        XAxisProps={{
          dataKey: 'timestamp',
          formatter: (value: string) => formatChartDate({
            date: value, 
            format: 'MMM DD',
            locale: intl.locale,
            dateRange,
            ...getBoundaryTimestamps(chartData)
          }),
        }}
        YAxisProps={{
          dataKey: 'hashrate',
          interval: 0,
          domain: [0, (dataMax: any) => {
            return dataMax * 1.2;
          }],
          tick: {
            style: {
              whiteSpace: 'normal',
            },
          },
          formatter: getFormattedPower,
          width: 52,
        }}
        tooltipLabelFormatter={(value) => formatChartDate({ date: value, locale: intl.locale })}
        tooltipValueFormatter={(value: any, _label: string, props: any) => {
          const { dataKey } = props;
          if (dataKey === 'price') {
            return formatCurrency(value);
          } else {
            return getFormattedPower(value)
          }
        }}
        lines={[
          {
            type: 'AREA',
            name: intl.formatMessage({ id: 'hashrates.realtime' }),
            dataKey: 'hashrate',
            stroke: '#f1a920',
            fill: 'orange',
          },
          {
            type: 'LINE',
            name: intl.formatMessage({ id: 'price' }),
            dataKey: 'price',
            stroke: '#464E59',
          }
        ]}
        tooltipLabelColors={{
          price: '#00CCAA'
        }}
      />
    </Box>
  );
}

export default NetworkHashrateChart;
