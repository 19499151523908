import React from 'react';
import _get from 'lodash/get';
import jsonLd from '../constants/jsonLd'
import _trimEnd from 'lodash/trimEnd';
import { useNavigation } from "@/processes/Router";
import { useRouter } from "next/router";
import { FAQPageJsonLd } from "next-seo";


type JsonLdSchemasProps = {
}

export default function JsonLdSchema(props: JsonLdSchemasProps) {
  const router = useRouter();
  const { locale } = useNavigation();

  const getMainEntityBy = (path: string, locale: string) => {
    const trimmedSlashPath = _trimEnd(path, '/');
    const trimmedLangPath = _trimEnd(trimmedSlashPath, `_${locale}`);

    const allPages = _get(jsonLd, ["", locale], [])
    const pageByPath = _get(jsonLd, [trimmedLangPath, locale], [])

    return [...allPages, ...pageByPath]
  }

  const mainEntity = getMainEntityBy(_get(router, 'asPath'), locale);

  return (
    <FAQPageJsonLd
      mainEntity={mainEntity}
    />
  )
}
