import { useIntl } from 'react-intl';

interface MediaObj {
  media: string;
  src: string;
  size: [number, number];
}

interface PictureProps {
  className?: string;
  src: string;
  loading?: 'lazy' | 'eager';
  alt?: string;
  medias?: MediaObj[];
  formats?: string[];
  locales?: string[];
  size?: [number, number];
  [key: string]: any;
}

const Picture = (props: PictureProps) => {
  const intl = useIntl();
  const { locale } = intl;

  const {
    src,
    loading = 'lazy',
    alt = '',
    medias = [],
    formats = ['png'],
    locales = ['en'],
    size = [1000, 640],
    className = '',
    ...rest
  } = props;

  const [width, height] = size;
  const localePrefix = locale === 'en' ? '' : `-${locale}`;
  const isLocaleExists = locales.includes(locale);
  const [srcPath, srcFormat] = src.split('.');
  const fullSrc = `${srcPath}${isLocaleExists ? localePrefix : ''}.${srcFormat}`;

  return (
    <picture className={className} {...rest}>
      {medias.map((mediaObj) => {
        const { media, src, size } = mediaObj;
        return formats.map((format) => {
          const [srcPath] = src.split('.');
          const srcSet = `${srcPath}${isLocaleExists ? localePrefix : ''}.${format}`;
          const type = `image/${format}`;
          const [width, height] = size;
          return (
            <source key={srcSet} media={media} width={width} height={height} srcSet={srcSet} type={type}/>
          )
        })
      })}

      <img src={fullSrc} width={width} height={height} alt={alt} loading={loading}/>
    </picture>
  );
}

export default Picture;
