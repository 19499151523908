import { useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl'
import cx from 'classnames';
import s from './ProfitCalculator.module.scss';
import _get from 'lodash/get';
import _debounce from "lodash/debounce";
import { getPower } from '@/shared/utils/formatters';
import { toFixed } from '@/shared/utils/toFixed';
import { sendGtmCustomEvent } from '@/processes/CookieBanner';
import useCryptoCurrencies from "@/processes/PoolStats/hooks/useCryptoCurrencies";
import useCoinsStats from "@/processes/PoolStats/hooks/useCoinsStats";
import { IconArrowUp, IconConvert } from "@/shared/ui/Icons";
import AdvancedCalculator from "~/ProfitCalculator/components/AdvancedCalculator";
import { useNavigation } from "@/processes/Router";
import { FIAT_DECIMALS } from '~/ProfitCalculator/constants/fiat';

type ProfitCalculatorProps = {
  className?: string;
}

const ProfitCalculator = (props: ProfitCalculatorProps) => {
  const {
    className = '',
  } = props;
  const { coin, isGRAM } = useNavigation();
  const { coinsStats } = useCoinsStats();
  const { cryptoCurrencies } = useCryptoCurrencies();

  const cryptoCurrency = cryptoCurrencies.find((item: any) => item.name === coin);
  const coinStats = coinsStats[coin];

  const profitPerPower = _get(cryptoCurrency, 'profitPerPower', 0) || 0;
  const profitPerPowerFormatted = getPower(profitPerPower, 0);

  const perPower = `${profitPerPowerFormatted.suffix}`;
  const profitPerPowerValueFormatted = Number(profitPerPowerFormatted.value) || 1;
  const defaultValue = profitPerPowerValueFormatted;

  const exchangeRateUSD = _get(coinStats, 'exchangeRates.USD', 0) || 0;
  const expectedReward24H = _get(coinStats, 'expectedReward24H', 0) || 0;

  const [revenue, setRevenue] = useState(0);
  const [fiatRevenue, setFiatRevenue] = useState(0);
  const [isAdvancedCalc, setIsAdvancedCalc] = useState(false);
  const [hashrateInput, setHashrateInput] = useState(1);

  const handleDebounceFn = (callback: () => void) => {
    if (typeof callback === 'function') {
      callback();
    }
  };
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

  const handleHashrateChange = (e: any) => {
    setHashrateInput(e.target.value);
    handleProfitCalc();
  };

  const handleProfitCalc = (skipGTM = false) => {
    const value = hashrateInput || 0;

    const total = (expectedReward24H / profitPerPowerValueFormatted) * value;
    const fiatTotal = total * exchangeRateUSD

    setRevenue(Number(total.toFixed(8)));
    // HIP-941: need to display 4 number after .
    setFiatRevenue(Number(toFixed(fiatTotal, FIAT_DECIMALS)));

    if (!skipGTM) {
      debounceFn(() => {
        sendGtmCustomEvent({
          category: 'site_pool',
          action: 'hashrate_adjusted',
          label: value,
        });
      });
    }
  };

  const handleAdvancedCalc = () => {
    setIsAdvancedCalc(!isAdvancedCalc);
  };

  useEffect(() => {
    handleProfitCalc(true);
  }, [cryptoCurrency, coinStats, hashrateInput]);

  useEffect(() => {
    setHashrateInput(defaultValue);
  }, [defaultValue]);

  return (
    <section className={cx(s.root, className)}>
      <div className={s.row}>
        <div className={s.col}>
          <label className={s.formGroup}>
            <span className={s.inputLabel}>
              <FormattedMessage id="Hashrate"/>
            </span>
            <div className="flex items-center">
              <div className={s.inputGroup}>
                <input
                  className={s.inputControl}
                  onChange={handleHashrateChange}
                  value={hashrateInput}
                  type="number"
                  min={1}
                />
                <span className={cx(s.inputGroupText, s.suffix)}>
                  {perPower}
                </span>
              </div>
              <span className={s.separator}>
                <IconConvert />
              </span>
            </div>
          </label>
        </div>
        <div className={s.col}>
          <label className={s.formGroup}>
            <span className={s.inputLabel}><FormattedMessage id="Revenue"/></span>
            <div className="flex md:items-center flex-col md:flex-row">
              <div className={s.inputGroup}>
                <input className={s.inputControl} type="text" readOnly placeholder="1" value={revenue}/>
                <span className={cx(s.inputGroupText, s.suffix)}>{coin}/<FormattedMessage id="day"/></span>
              </div>
              <span className={cx(s.calcProfit)}>≈ $<span>{fiatRevenue}</span></span>
            </div>
          </label>
        </div>

        {!isGRAM && (
          <div className={s.col}>
            <button
              type="button"
              className={cx(s.toggleBtn, 'theme-advanced-calc')}
              onClick={handleAdvancedCalc}
              aria-expanded={isAdvancedCalc}
              aria-controls="advanced-calc"
            >
              <FormattedMessage id="landing.profit.calculator.advanced.button"/>
              <IconArrowUp className={cx(s.toggleArrow, "theme-color")}/>
            </button>
          </div>
        )}
      </div>
      {isAdvancedCalc &&
        <AdvancedCalculator />
      }
    </section>
  );
}

export default ProfitCalculator;