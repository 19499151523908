import Advantages from "@/widgets/Landing/dnx/components/Advantages";
import HowItWorks from "@/widgets/Landing/dnx/components/HowItWorks";
import StartMiningWith from "@/widgets/Landing/dnx/components/StartMiningWith";
import Support from "@/widgets/Landing/dnx/components/Support";
import Faq from "@/widgets/Landing/dnx/components/Faq";
import ProfitCalculator from "~/ProfitCalculator";
import AddressExplorer from "@/widgets/Landing/shared/AddressExplorer";
import QuickStats from "@/widgets/Landing/shared/QuickStats";
import Intro from  "@/widgets/Landing/both/Intro";
import MinerBlocks from "@/widgets/Landing/shared/MinerBlocks";
import JoinSection from "@/widgets/Landing/shared/JoinSection";
import { useMinerAccount } from "@/processes/MinerAccounts";
import HowToGetHiveonOs from "@/widgets/Landing/shared/HowToGetHiveonOs";

const LandingBlocks = () => {
  const { isAuth } = useMinerAccount();
  return (
    <>
      <Intro className={isAuth ? 'py-20 md:py-32' : 'pt-20 md:pt-32'} titleKey="l.dnx.title" descKey="l.dnx.desc" readMoreKey="l.dnx.desc2" />
      {!isAuth ? (
        <AddressExplorer className='my-6 sm:my-8' />
      ) : null}
      <ProfitCalculator className="my-6 sm:my-8 sm:mb-32" />
      <HowItWorks className="my-6 sm:my-8" />
      <Advantages className='my-20 md:my-40 md:mb-20'/>
      <QuickStats className="my-6 sm:my-8" />
      <MinerBlocks className="my-6 md:my-8" />
      <Support className='my-20 md:my-40 md:mt-20' />
      <StartMiningWith className='my-20 md:my-40' titleKey="landing.startMiningWith.title.v2" />
      <HowToGetHiveonOs className='my-20 md:my-40' />
      <Faq className='my-20 md:my-40' />
      <JoinSection className='pb-8 my-20 md:mb-20 md:mt-40' titleKey="footer.join.title.v2" />
    </>
  );
}

export default LandingBlocks;
