import React from "react";
import { FormattedMessage } from "react-intl";
import cx from "classnames";
import s from "./Advantages.module.scss";
import { animated } from 'react-spring';
import { useBlockAnimate } from '@/shared/hooks/useBlockAnimate';
import { useNavigation } from '@/processes/Router';
import { useThreshold } from '@/processes/PoolStats'
import Link from 'next/link';

type AdvantagesProps = {
  className?: string;
}

const Advantages = (props: AdvantagesProps) => {
  const { className } = props;
  const { coin, isBTC } = useNavigation();
  const { threshold } = useThreshold({ coin });

  const { ref: ref1, springStyles: springTitleStyles1 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref2, springStyles: springTitleStyles2 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref4, springStyles: springTitleStyles4 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref5, springStyles: springTitleStyles5 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });


  return (
    <div className={cx(s.root, className)} ref={ref1}>
      <animated.h2 className="landing-title" style={springTitleStyles1}>
        <FormattedMessage id={isBTC ? "landing.btc.advantages.title" : "landing.advantages.title"} values={{ coin, br: <br/>, span: (...chunks) => <span className="theme-text-highlight">{chunks}</span>, }} />
      </animated.h2>
      <div className={s.blocks}>
        <span className={cx(s.abstract1, '-left-80 -top-36')} aria-hidden={true}/>
        <span className={cx(s.abstract1, '-right-52 -bottom-36')} aria-hidden={true}/>
        
        <span className={cx(s.abstract2, '-top-10 left-0 right-0 mx-auto')} aria-hidden={true}/>
        <span className={cx(s.abstract2, '-bottom-4 left-0 right-0 mx-auto transform rotate-180')} aria-hidden={true}/>
        <span className={cx(s.abstract2, '-left-44 -ml-2.5 top-0 bottom-0 my-auto transform -rotate-90')} aria-hidden={true}/>
        <span className={cx(s.abstract2, '-right-44 -mr-2.5 top-0 bottom-0 my-auto transform rotate-90')} aria-hidden={true}/>
       
        <animated.div ref={ref2} style={springTitleStyles2} className={s.blocksGrid}>
          <div className={s.blocksCol}>
            <div className={cx(s.blockWrap, s.blockmHLang)}>
              <div className={cx(s.block)}>
                <span
                  className={cx(
                    "theme-text-highlight shadow text-3xl sm:text-5xl font-bold"
                  )}
                >
                  <FormattedMessage id="landing.advantages.products.title" />{' '}
                </span>
                <span className="font-semibold mt-4 text-xl sm:text-2xl">
                  <FormattedMessage id="landing.advantages.products.desc"/>
                </span>
              </div>
              <div className={cx(s.blockHover, s.blockHoverPos1)}>
                <FormattedMessage id="landing.advantages.products.hover"/>
              </div>
            </div>

          </div>
          <div className={s.blocksCol}>
            <div className={cx(s.blockWrap, s.blockmHLang)}>
              <div className={cx(s.block)}>
                <Link
                  href="https://hiveon.com/energy/"
                  className={cx(
                    "theme-text-highlight hover-light shadow font-bold text-3xl sm:text-5xl"
                  )}
                >
                  <FormattedMessage id="landing.advantages.energy.title" />
                </Link>
                <span className={cx("font-semibold mt-4 text-xl sm:text-2xl")}>
                  <FormattedMessage id="landing.advantages.energy.desc" />
                </span>
              </div>
              <div className={cx(s.blockHover, s.blockHoverPos2)}>
                <FormattedMessage id="landing.advantages.energy.hover" />
              </div>
            </div>
          </div>
        </animated.div>

        <animated.div ref={ref4} style={springTitleStyles4} className={s.blocksGrid}>
          <div className={s.blocksCol}>
          <div className={cx(s.blockWrap, s.blockmHLang)}>
              <div className={cx(s.block)}>
                <span
                  className={cx(
                    "theme-text-highlight shadow text-3xl sm:text-5xl font-bold"
                  )}
                >
                  <FormattedMessage id="landing.advantages.ahub.title" />{' '}
                </span>
                <span className="font-semibold mt-4 text-xl sm:text-2xl">
                  <FormattedMessage id="landing.advantages.ahub.desc" values={{ coin }}/>
                </span>
              </div>
              <div className={cx(s.blockHover, s.blockHoverPos1)}>
                <FormattedMessage id="landing.advantages.ahub.hover" />
              </div>
            </div>
          </div>

          <div className={s.blocksCol}>
            <div className={cx(s.blockWrap, s.blockmHLang)}>
              <div className={cx(s.block)}>
                <span
                    className={cx(
                      "theme-text-highlight shadow font-bold text-3xl sm:text-5xl"
                    )}
                  >
                  0% {' '} <FormattedMessage id="landing.advantages.fee.title" />
                </span>
                <span className={cx("font-semibold mt-4 text-xl sm:text-2xl")}>
                  <FormattedMessage id="landing.advantages.fee.desc" />
                </span>
              </div>

              <div className={cx(s.blockHover, s.blockHoverPos2)}>
                <FormattedMessage id="landing.advantages.fee.hover" values={{ coin }}/>
              </div>
            </div>
          </div>
        </animated.div>

        <animated.div ref={ref5} style={springTitleStyles5} className={s.blocksGrid}>
          <div className={s.blocksCol}>
            <div className={cx(s.blockWrap, s.blockmHLang)}>
              <div className={cx(s.block)}>
                <div className="flex justify-between relative -mx-3">
                  <div className={cx("flex flex-col items-center w-1/2 px-3", s.separator)}>
                    <span className={cx("mb-4", s.title4)}>
                      <FormattedMessage id="landing.advantages.minPayout.title" />
                    </span>
                    <span className="theme-text-highlight shadow font-bold text-2xl sm:text-4/5xl">
                      {threshold} {coin}
                    </span>
                  </div>

                  <div className="flex flex-col items-center w-1/2 px-3">
                    <span className={cx("mb-4 text-center", s.title4)}>
                      <FormattedMessage id="landing.advantages.dailyPayout.title" />
                    </span>
                    <div className="sm:flex sm:flex-col items-center text-center">
                      <span className="mr-2 text-center sm:mr-0 sm:mb-3 theme-text-highlight shadow font-bold text-2xl sm:text-4xl">
                        <FormattedMessage id="landing.advantages.dailyPayout.time" />
                      </span>
                      <span className="theme-text-highlight shadow font-bold text-md text-2xl">UTC</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className={s.blocksCol}>
            <div className={cx(s.blockWrap, s.blockmHLang)}>
            <div className={cx(s.block)}>
                <span
                    className={cx(
                      "theme-text-highlight shadow font-bold text-3xl sm:text-5xl"
                    )}
                  >
                  <FormattedMessage id="rewardType.FPPS" />
                </span>
                <span className={cx("font-semibold mt-4 text-xl sm:text-2xl")}>
                  <FormattedMessage id="landing.advantages.fpps.desc" />
                </span>
              </div>
              <div className={cx(s.blockHover, s.blockHoverPos2)}>
                <FormattedMessage id="landing.advantages.fpps.title.hover" />
              </div>
            </div>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default Advantages;
