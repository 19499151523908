import React, { useState } from "react";
import cx from "classnames";
import s from "./Intro.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { animated } from 'react-spring';
import { useBlockAnimate } from '@/shared/hooks/useBlockAnimate';
import ThemeButton from '@/shared/ui/ThemeButton';
import { useNavigation } from '@/processes/Router';
import { useMinerAccount } from "@/processes/MinerAccounts";

type IntroProps = {
  className: string;
  titleKey?: string;
  descKey?: string;
  readMoreKey?: string;
}

const Intro = (props: IntroProps) => {
  const { 
    className, 
    titleKey = 'l.shared.title',
    descKey = 'l.shared.desc',
    readMoreKey,
  } = props;
  const intl = useIntl();

  const { ref: ref1, springStyles: springTitleStyles1 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { coin, coinTitle, navigate, poolDashboardLink } = useNavigation();
  const { isAuth } = useMinerAccount();

  const [isReadMore, setIsReadMore] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div ref={ref1} className={cx(className)}>
      <animated.h1 className="text-5xl/4 sm:text-8xl font-bold mb-8 sm:mb-12" style={springTitleStyles1}>
        <FormattedMessage
          id={titleKey}
          values={{
            span: (...chunks) => (
              <span className="theme-text-highlight">{chunks}</span>
            ),
            br: <br/>,
            coin,
            coinTitle,
          }}
        />
      </animated.h1>
   
      <animated.p 
        className="text-base sm:text-xl my-8 sm:my-16 leading-6 max-w-[850px]"
        style={springTitleStyles1}
      >
        <FormattedMessage id={descKey} values={{
          coinTitle, 
        }}/>
        {readMoreKey && !isReadMore ? <>{' '}<button className="text-dynex" onClick={toggleReadMore}>{intl.formatMessage({id: 'Read more'})}...</button></> : null}

        {readMoreKey ? <span className={cx('mt-4 lg:mt-8 block', {'hidden': !isReadMore})}>
          <FormattedMessage id={readMoreKey} values={{
            coinTitle,
          }}/>
          {' '}
          <button className="text-dynex" onClick={toggleReadMore}>{intl.formatMessage({id: 'Read less'})}</button>
        </span> : null}
      </animated.p>

      {/* Show Dashboard only for Auth user */}
      {/* For guest show address explorer */}
      {isAuth ? (
        <ThemeButton onClick={() => {
          navigate(poolDashboardLink);
        }}><FormattedMessage id="dashboard" /></ThemeButton>
      ) : null}
      
    </div>
  );
};

export default Intro;
