import React, { forwardRef } from 'react';
import * as AccordionLib from '@radix-ui/react-accordion';
import cx from 'classnames';
import s from './Accordion.module.scss';

type AccordionProps = {
  items?: any[],
  className?: string;
  triggerClassName?: string;
  contentClassName?: string;
  itemClassName?: string;
  defaultValue?: string;
  children?: React.ReactNode;
}

const Accordion = (props: AccordionProps) => {
  const {
    items = [],
    defaultValue,
    className,
    triggerClassName,
    contentClassName,
    itemClassName,
    children,
    ...restProps
  } = props;

  return (
    <AccordionLib.Root
      className={cx(s.root, className)}
      type="single"
      collapsible
      defaultValue={defaultValue}
      {...restProps}
    >
      { children ? children :
        items.map((item) => (
          <AccordionItem key={item.value} className={cx(s.accordionItem, itemClassName)} value={item.value}>
            <AccordionTrigger className={cx(s.accordionTrigger, triggerClassName)}>{item.trigger}</AccordionTrigger>
            <AccordionContent className={cx(s.accordionContent, contentClassName)}>{item.content}</AccordionContent>
          </AccordionItem>
        ))
      }
    </AccordionLib.Root>
  );
}

export const AccordionItem = forwardRef(({ children, className, ...props }: any, forwardedRef) => (
  <AccordionLib.Item
    className={cx(
      s.accordionItem,
      className
    )}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </AccordionLib.Item>
));

export const AccordionTrigger = forwardRef(({ children, className, ...props }: any, forwardedRef) => (
  <AccordionLib.Header className="flex">
    <AccordionLib.Trigger
      className={cx(
        s.accordionTrigger,
        className
      )}
      {...props}
      ref={forwardedRef}
    >
      <div className="mb-4 mt-2 leading-normal">
        {children}
      </div>
    </AccordionLib.Trigger>
  </AccordionLib.Header>
));

export const AccordionContent = forwardRef(({ children, className, ...props }: any, forwardedRef): any => (
  <AccordionLib.Content
    className={cx(
      s.accordionContent,
      className
    )}
    {...props}
    ref={forwardedRef}
  >
    <div className="mb-6">
      {children}
    </div>
  </AccordionLib.Content>
));

AccordionItem.displayName = 'AccordionItem';
AccordionTrigger.displayName = 'AccordionTrigger';
AccordionContent.displayName = 'AccordionContent';

export default Accordion;
