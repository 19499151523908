import cx from "classnames";
import s from "./ChartTooltip.module.scss";

const ChartTooltip = (props: any) => {
  const { 
    active, payload, label, 
    colors = {}, 
    formatter = (v: any) => v, 
    labelFormatter = (v: any) => v,
  } = props;
  
  if (active && payload && payload.length) {
    return (
      <div className={s.root}>
        <div className={cx(s.tooltipItem, s.tooltipLabel)}>
          {labelFormatter(label)}
        </div>
        {payload.map((item: any, idx: number) => {
          return (
            <div
              className={s.tooltipItem}
              key={`tooltip-item-${idx}`}
              style={{ color: colors[item.dataKey] || item.color }}
            >
              <span className={s.tooltipItemLabel}>{item.name}:</span>{" "}
              <span className={s.tooltipItemValue}>
                {formatter(item.value, item.name, item)}
              </span>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

export default ChartTooltip;
