import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import _get from 'lodash/get';
import { useNavigation } from '@/processes/Router';
import BTCLandingBlocks from './btc/LandingBlocks';
import RVNLandingBlocks from './rvn/LandingBlocks';
import ETCLandingBlocks from './etc/LandingBlocks';
import KASLandingBlocks from './kas/LandingBlocks';
import DNXLandingBlocks from './dnx/LandingBlocks';
import GRAMLandingBlocks from './gram/LandingBlocks';

const LANDING_BLOCKS = {
  BTC: BTCLandingBlocks,
  RVN: RVNLandingBlocks,
  ETC: ETCLandingBlocks,
  KAS: KASLandingBlocks,
  DNX: DNXLandingBlocks,
  GRAM: GRAMLandingBlocks,
}

const Landing = () => {
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.className = 'landing';
    }
    return () => {
      if (body) { body.className = ''; }
    }
  }, []);

  const { coin } = useNavigation();
  const Blocks = _get(LANDING_BLOCKS, coin);
  return (
    <>
      {Blocks && <Blocks/>}
    </>
  );
}

export default Landing;
