import { useState, useEffect } from "react";
import cx from 'classnames';
import s from './InputWithSelect.module.scss';
import Select from "@/shared/ui/Select";
import FieldWrapper from "@/shared/ui/FieldWrapper";
import { convertInputValueToNumber } from "@/shared/utils/formatters";

type InputWithSelectProps = {
  labelInfo?: string;
  labelPostfix?: string;
  inputClassName?: string;
  labelTextClassName?: string;
  type?: string,
  placeholder?: string,
  hint?: string;
  error?: any;
  full?: boolean;
  label: string;
  id: string;
  min?: number;
  step?: number;
  inputValue: number | string;
  defaultInputValue?: number | string;
  inputOnChange: (e: any) => void;
  selectOptions: any[];
  selectValue: any;
  selectOnChange: (e: any) => void;
  selectProps?: any;
  inputProps?: any;
}

const selectStyles = {
  control: (base :any) => ({
    ...base,
    border:'none !important',
    boxShadow: 'none !important',
    borderTopRightRadius: '0.75rem !important',
    borderBottomRightRadius: '0.75rem !important',
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
  }),
};

const InputWithSelect = (props: InputWithSelectProps) => {
  const {
    label,
    labelInfo = '',
    labelPostfix = '',
    labelTextClassName = '',
    type = 'number',
    id = '',
    inputClassName = '',
    hint = '',
    error = '',
    full = false,
    inputValue,
    defaultInputValue = 0,
    inputOnChange,
    selectOptions,
    selectValue,
    selectOnChange,
    selectProps = {},
    inputProps = {},
    step = 1,
    min = 0,
  } = props;

  const [value, setValue] = useState(inputValue ? inputValue : defaultInputValue);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);
  
  const handleInputChange = (e: any) => {
    let value = convertInputValueToNumber(e.target.value);

    setValue(e.target.value);

    inputOnChange(value === 0 ? defaultInputValue : value);
  };

  const handleInputBlur = (e: any) => {
    const value = convertInputValueToNumber(e.target.value);
    const finalValue = value === 0 ? defaultInputValue : value;

    setValue(finalValue);
    inputOnChange(finalValue);
  }

  return (
    <FieldWrapper
      label={label}
      labelInfo={labelInfo}
      labelPostfix={labelPostfix}
      inputId={id}
      hint={hint}
      error={error}
      full={full}
      labelTextClassName={labelTextClassName}
    >
      <div className={s.inputGroup}>
        <input
          id={id}
          value={value}
          type={type}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          min={min}
          step={step}
          className={cx(s.input, inputClassName)}
          {...inputProps}
        />
        <Select
          className={cx(s.suffix, 'flex-shrink-0')}
          options={selectOptions}
          value={selectValue}
          controlClassName={s.selectControl}
          onChange={selectOnChange}
          size="lg"
          textSize="base"
          styles={selectStyles}
          menuFull
          {...selectProps}
        />
      </div>
    </FieldWrapper>
  );
}

export default InputWithSelect;
