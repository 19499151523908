import { useState } from 'react';
import cx from 'classnames';
import s from './HardwareCounter.module.scss';
import { IconMinus, IconPlus } from "@/shared/ui/Icons";


type HardwareCounterProps = {
  handleSubtractCount: () => void;
  handleAddCount: () => void;
  handleUnitsAmountChange: (e: any) => void;
  unitsAmount: number;
}
const HardwareCounter = (props: HardwareCounterProps) => {
  const {
    handleSubtractCount,
    handleAddCount,
    unitsAmount,
    handleUnitsAmountChange,
  } = props;

  const emptyState = '';
  const [hoveredElement, setHoveredElement] = useState(emptyState);
  const [activeElement, setActiveElement] = useState(emptyState);

  return (
    <div className={cx(s.root,
      {
        [hoveredElement]: hoveredElement,
        [activeElement]: activeElement
      })}>
      <button
        type="button"
        name="prefix"
        className={cx(s.prefix)}
        onClick={handleSubtractCount}
        onMouseEnter={() => setHoveredElement('theme-prefix-hovered')}
        onMouseLeave={() => setHoveredElement(emptyState)}
        onFocus={() => setActiveElement('theme-prefix-focused')}
        onBlur={() => setActiveElement(emptyState)}
      >
        <IconMinus className="text-white"/>
      </button>
      <div className={s.inputWrapper}>
        <input
          value={unitsAmount}
          type="number"
          onChange={handleUnitsAmountChange}
          onMouseEnter={() => setHoveredElement('theme-input-hovered')}
          onMouseLeave={() => setHoveredElement(emptyState)}
          className={s.input}
          onFocus={() => setActiveElement('theme-input-focused')}
          onBlur={() => setActiveElement(emptyState)}
        />
      </div>
      <button
        type="button"
        name="suffix"
        className={cx(s.suffix)}
        onClick={handleAddCount}
        onMouseEnter={() => setHoveredElement('theme-suffix-hovered')}
        onMouseLeave={() => setHoveredElement(emptyState)}
        onFocus={() => setActiveElement('theme-suffix-focused')}
        onBlur={() => setActiveElement(emptyState)}
      >
        <IconPlus className="text-white"/>
      </button>
    </div>
  );
}

export default HardwareCounter;
