import { BASE_HOST } from '@/shared/constants/env';
import s from './JoinButton.module.scss';
import { useNavigation } from '@/processes/Router';
import ThemeButton from '@/shared/ui/ThemeButton';
import { useMinerAccount } from "@/processes/MinerAccounts";
import { loginWithRedirect } from '@/global/utils/apiHelpers';

type JoinButtonProps = {
  title: string;
  gtmButton?: string;
  gtmBlock?: string;
}

const JoinButton = (props: JoinButtonProps) => {
  const {
    title = '',
    gtmButton = '',
    gtmBlock = '',
  } = props;

  const { isAuth } = useMinerAccount();
  const { coinLower } = useNavigation();
  const redirectAfterLogin = `${BASE_HOST}/${coinLower}/dashboard`;
  
  // for auth user hide button
  if (isAuth) { return null; }

  return (
    <div className={s.root}>
      <ThemeButton 
        component='a' 
        target="_blank" 
        href={loginWithRedirect(redirectAfterLogin)}
        data-gtm-category="site_pool" 
        data-gtm-action="get_started_click" 
        data-gtm-label={JSON.stringify({
          button: gtmButton,
          block: gtmBlock,
        })}
      >
        {title}
      </ThemeButton>
    </div>
  );
}

export default JoinButton;
