import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import cx from "classnames";
import s from "./HowItWorks.module.scss";
import { animated } from 'react-spring';
import { useBlockAnimate } from '@/shared/hooks/useBlockAnimate';
import { IconDynexHexagonNumber1, IconDynexHexagonNumber2, IconDynexHexagonNumber3, IconDynexHexagonNumber4 } from "@/shared/ui/Icons";

type HowItWorksProps = {
  className?: string;
}

const HOW_IT_WORK_LIST = [
  {
    title: 'landing.dnx.howItWorks.title.1',
    desc: 'landing.dnx.howItWorks.desc.1',
  },
  {
    title: 'landing.dnx.howItWorks.title.2',
    desc: 'landing.dnx.howItWorks.desc.2',
  },
  {
    title: 'landing.dnx.howItWorks.title.3',
    desc: 'landing.dnx.howItWorks.desc.3',
  },
];

const HOW_TO_USE_LIST = [
  {
    title: 'landing.dnx.howToUse.title.1',
    desc: 'landing.dnx.howToUse.desc.1',
  },
  {
    title: 'landing.dnx.howToUse.title.2',
    desc: 'landing.dnx.howToUse.desc.2',
  },
  {
    title: 'landing.dnx.howToUse.title.3',
    desc: 'landing.dnx.howToUse.desc.3',
  },
];

const MINING_CALC_LIST = [
  {
    title: 'landing.dnx.howItWorks.list.item1',
    Icon: IconDynexHexagonNumber1,
  },
  {
    title: 'landing.dnx.howItWorks.list.item2',
    Icon: IconDynexHexagonNumber2,
  },
  {
    title: 'landing.dnx.howItWorks.list.item3',
    Icon: IconDynexHexagonNumber3,
  },
  {
    title: 'landing.dnx.howItWorks.list.item4',
    Icon: IconDynexHexagonNumber4,
  },
];
const HowItWorks = (props: HowItWorksProps) => {
  const { className } = props;
  const intl = useIntl();

  const { ref: ref1, springStyles: springTitleStyles1 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });

  const [isReadMore, setIsReadMore] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div className={cx(s.root, className)} ref={ref1}>
      <animated.div ref={ref1} style={springTitleStyles1} className="mb-7 lg:mb-14">
        <div className="flex items-center gap-10">
          <div className="flex-grow lg:w-1/2 hidden lg:inline-flex">
            <span className={cx(s.abstract1)} aria-hidden={true}/>
          </div>
          <div className="flex-grow lg:w-1/2">
            <h2 className="text-2xl lg:text-4xl font-bold mb-4 leading-9">
              <FormattedMessage id="landing.dnx.howItWorks.title" />
            </h2>
            <div className="text-white/[0.6] text-base leading-5">
              <FormattedMessage id="landing.dnx.howItWorks.desc" />

              <span className={cx('mt-4 lg:mt-8 block', {'hidden': !isReadMore})}>
                <FormattedMessage id="landing.dnx.howItWorks.descReadMore" />
              </span>
            </div>

            {!isReadMore ? <button className="text-dynex" onClick={toggleReadMore}>{intl.formatMessage({id: 'Read more'})}...</button> : null}
          </div>
        </div>
      </animated.div>

      <div className={cx({'hidden': !isReadMore})}>
        <ul className={s.cards} >
          {HOW_IT_WORK_LIST.map((item, index) => (
            <li key={index}>
              <div className="text-2xl font-bold mb-4 leading-6">
                {intl.formatMessage({id: item.title})}
              </div>
              <div className="text-white/[0.6] text-base leading-5">
                {intl.formatMessage({id: item.desc})}
              </div>
            </li>
          ))}
        </ul>

        <h2 className={cx(s.listTitle)}>
          <FormattedMessage id="landing.dnx.howItWorks.list.title" values={{
            span: (...chunks) => <span className="theme-gradient-color">{chunks}</span>,
          }}/>
        </h2>
        <ul className={cx(s.list)}>
          {MINING_CALC_LIST.map(({title, Icon}, index) => (
            <li key={index} className={cx(s.listItem, {[s.connector]: index !== MINING_CALC_LIST.length -1})}>
              <Icon className={s.listIcon} />
              <FormattedMessage id={title} values={{
                b: (...chunks) => <b className="text-white font-medium">{chunks}</b>,
              }}/>
            </li>
          ))}
          <li className={cx(s.listItem)} >
            <button className="text-dynex" onClick={toggleReadMore}>{intl.formatMessage({id: 'Read less'})}</button>
          </li>
        </ul>
      </div>

      <animated.ul style={springTitleStyles1} className={cx(s.cards, 'mt-8')}>
        {HOW_TO_USE_LIST.map((item, index) => (
          <li key={index}>
            <div className="text-2xl font-bold mb-4 leading-6">
              {intl.formatMessage({id: item.title})}
            </div>
            <div className="text-white/[0.6] text-base leading-5">
              {intl.formatMessage({id: item.desc})}
            </div>
          </li>
        ))}
      </animated.ul>
    </div>
  );
};

export default HowItWorks;
