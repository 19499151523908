import Intro from  "@/widgets/Landing/accounting/Intro";
import Support from "@/widgets/Landing/shared/Support";
import HowToGetHiveonOs from "@/widgets/Landing/shared/HowToGetHiveonOs";
import StartMiningWith from "@/widgets/Landing/shared/StartMiningWith";
import ProfitCalculator from "~/ProfitCalculator";
import QuickStats from "@/widgets/Landing/btc/components/QuickStats";
import MinerBlocks from "@/widgets/Landing/btc/components/MinerBlocks";
import JoinSection from "@/widgets/Landing/shared/JoinSection";
import Advantages from "@/widgets/Landing/btc/components/Advantages";

const LandingBlocks = () => {
  return (
    <>
      <Intro className='py-20 md:py-32' titleKey="l.btc.title" descKey="l.btc.desc"/>
      <ProfitCalculator className="my-6 sm:my-8 sm:mb-32"/>
      <QuickStats className="my-6 sm:my-8"/>
      <MinerBlocks className="my-6 md:my-8" />
      <Advantages className='my-20 md:my-40 md:mb-20'/>
      <Support className='my-20 md:my-40 md:mt-20' />
      <StartMiningWith className='my-20 md:my-40' />
      <HowToGetHiveonOs className='my-20 md:my-40' />
      <JoinSection className='pb-8 my-20 md:mb-20 md:mt-40' />
    </>
  );
}

export default LandingBlocks;
