import Support from "@/widgets/Landing/shared/Support";
import Advantages from "@/widgets/Landing/etc/components/Advantages";
import StartMiningWith from "@/widgets/Landing/shared/StartMiningWith";
import AddressExplorer from "@/widgets/Landing/shared/AddressExplorer";
import ProfitCalculator from "~/ProfitCalculator";
import QuickStats from "@/widgets/Landing/shared/QuickStats";
import Intro from  "@/widgets/Landing/accounting/Intro";
import MinerBlocks from "@/widgets/Landing/shared/MinerBlocks";
import HowToGetHiveonOs from "@/widgets/Landing/shared/HowToGetHiveonOs";
import JoinSection from "@/widgets/Landing/shared/JoinSection";
import { useMinerAccount } from '@/processes/MinerAccounts';

const LandingBlocks = () => {
  const { isAuth } = useMinerAccount();

  return (
    <>
      <Intro className={isAuth ? 'py-20 md:py-32' : 'pt-20 md:pt-32'} />
      <AddressExplorer className={'my-6 sm:my-8'} />
      <ProfitCalculator className="my-6 sm:my-8 sm:mb-32" />
      <QuickStats className="my-6 sm:my-8" />
      <MinerBlocks className="my-6 md:my-8" />
      <Advantages className='my-20 md:my-40 md:mb-20'/>
      <Support className='my-20 md:my-40 md:mt-20' />
      <StartMiningWith className='my-20 md:my-40' />
      <HowToGetHiveonOs className='my-20 md:my-40' />
      <JoinSection className='pb-8 my-20 md:mb-20 md:mt-40' />
    </>
  );
}

export default LandingBlocks;
