export const LINK_TYPES = {
  url: 'LINK_TYPES.url',
  email: 'LINK_TYPES.email',
};

export const DOMAIN_TYPES = {
  hiveon: 'hiveon',
  hiveos: 'hiveos',
  pool: 'pool',
  forum: 'forum',
  manage: 'manage',
  external: 'external',
};
