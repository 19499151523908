export default {
  '/dnx-mining-pool': {
    'en': [
      {
        questionName: 'What is Dynex Crypto',
        acceptedAnswerText: 'Dynex (DNX) is a neuromorphic supercomputing blockchain built on the DynexSolve chip algorithm, enabling GPUs around the world to operate as one decentralized supercomputer. Dynex network uses both Proof-of-Work (PoW) and Proof-of-useful-Work (PouW) systems simultaneously, which makes it capable of performing computations at the speed and efficiency that may exceed quantum computing. The project aims to solve real-world problems and has a whole bunch of computational-intense use cases lined up as of October 2023.',
      },
      {
        questionName: 'What is the Dynex mining algorithm?',
        acceptedAnswerText: 'The Dynex mining algorithm is called DynexSolve. As of October 2023, it’s only supported by OneZeroMiner (for Nvidia GPUs) and SRBMiner (for AMD/Nvidia GPUs) mining software.',
      },
      {
        questionName: 'What is the total Hashrate of Dynex?',
        acceptedAnswerText: 'As of October 2023, Dynex network hashrate sits at ~2.22 GH/s. For more recent information about DNX hashrate, you can visit regularly updated resources like Hashrate.no.',
      },
      {
        questionName: 'How to mine Dynex coin?',
        acceptedAnswerText: 'Make the most of your hardware and earn maximum DNX coins with the Hiveon DNX Pool + Hiveon OS power couple. Mining with our pool gives you free Hiveon OS access for up to 4 devices (the usual price is $3 per device monthly), so it’s a win-win. And if you don’t know how to use Dynex mining pool yet, here’s a quick walkthrough of how to mine DNX on Hiveon OS using our pool: 1. Create a Hiveon account and install Hiveon OS on GPU rigs, then add them to your farm 2. Create a DNX wallet and add it in the Wallets tab. You can either install the Dynex GUI Wallet based app on your desktop or create a web wallet for receiving and sending DNX. We highly recommend setting up 2FA for extra security. DON’T mine directly to exchange wallet addresses, or you may lose your DNX payouts. Use your Dynex wallet deposit address for mining 3.Go to Flight Sheets and enter all the details 4.Hit the rocket icon to set off — and you’re mining DNX with Hiveon Dynex Pool 5.That’s how to mine DNX efficiently. As you mine, you\'ll accumulate shares in the pool. Your DNX rewards will be paid out to your wallet based on your contribution. For more tips on how to use Hiveon DNX Pool and Hiveon OS tools for overclocking, troubleshooting, and monitoring your farm to reach peak hardware performance, check out our Knowledge Base',
      },
      {
        questionName: 'How do I find the best mining pool for Dynex (DNX)?',
        acceptedAnswerText: 'To find the best mining pool for Dynex (DNX), research fee structure, payment system, server stability, and the availability of the pool’s customer support team. Check the historical uptime and find honest user reviews. Finally, connect to a pool and try mining with it for at least a week to see if you get satisfactory hashrate and receive the payouts consistently.',
      },
      {
        questionName: 'Is mining Dynex profitable?',
        acceptedAnswerText: 'This depends on your unique power costs and hardware hashrates. The DynexSolve algorithm is fairly low power compared to some other popular algorithms, which can help maintain profitability when power costs are on the higher side. Use our DNX mining calculator to see if it makes sense for you.',
      },
      {
        questionName: 'What is the best way to mine Dynex?',
        acceptedAnswerText: 'Currently, powerful GPUs are the best way to mine Dynex. Some of the graphics cards highly recommended by the Dynex miners community are AMD RX6600 XT, Nvidia RTX 3060 Ti, and AMD Radeon VII.',
      },
      {
        questionName: 'Can you mine Dynex with ASIC miner?',
        acceptedAnswerText: 'As of 2023, you can’t mine Dynex with any ASIC miner.',
      },
      {
        questionName: 'What is needed for Dynex mining?',
        acceptedAnswerText: 'The first thing you need to get into Dynex mining is a computer with one or several graphics cards (anything from a gaming PC to a GPU mining rig will do). You will also need a Dynex wallet, a reliable mining pool (such as Hiveon DNX Pool), appropriate mining software (OneZeroMiner for Nvidia or SRBMiner for AMD/Nvidia), and a mallob endpoint. A working internet connection is a must, too — and that’s it.',
      },
      {
        questionName: 'Where can I sell Dynex (DNX) coins?',
        acceptedAnswerText: 'As of 2023, the top 4 exchanges where you can sell Dynex (DNX) coins for USDT are MEXC, Lbank, BingX, and BitMart. Note that you can’t mine DNX directly to exchange addresses due to Dynex network restrictions. Use only your Dynex wallet deposit address for mining the coins.',
      },
      {
        questionName: 'What does DNX mining calculator show?',
        acceptedAnswerText: 'The DNX mining calculator shows you approximate profitability numbers. The actual results of your mining operations will vary based on your electricity costs and other influential factors.',
      },
    ]
  },
};
