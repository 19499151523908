import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import _get from "lodash/get";
import s from './MinerServerList.module.scss';
import { useNavigation } from "@/processes/Router";
import { useCryptoCurrency } from "@/processes/PoolStats";
import { sendGtmCustomEvent } from "@/processes/CookieBanner";
import Box from '@/shared/ui/Box'
import Copy from '@/shared/ui/Copy'

type MinerServerListProps = {
  className?: string,
}
const MinerServerList = (props: MinerServerListProps) => {
  const {
    className,
  } = props;

  const intl = useIntl();
  const { coin } = useNavigation();
  const cryptoCurrency = useCryptoCurrency({ coin });
  const servers = _get(cryptoCurrency, 'servers', []);
  const hasServers = servers.length > 0;
  
  if (!hasServers) { return null; }
  let isAtLeaseOneExtraPort = false;
  let isAtLeaseOneSSL = false;
  servers.forEach(({ ports, ssl_ports }: any) => {
    if (ports?.[1]) { isAtLeaseOneExtraPort = true; }
    if (ssl_ports?.[0]) { isAtLeaseOneSSL = true; }
  });

  return (
    <Box className={cx(className)} size='none'>
      <div className="text-base mb-5 text-white font-bold tracking-025">
        <FormattedMessage id="miner.server.list"/>
      </div>
      <table className={s.table}>
        <thead className="text-white/[0.6] text-sm">
          <tr>
            <th><FormattedMessage id="miner.pool.url"/></th>
            <th><FormattedMessage id="miner.port"/></th>
            {isAtLeaseOneExtraPort && <th><FormattedMessage id="miner.port"/></th>}
            {isAtLeaseOneSSL && <th><FormattedMessage id="miner.ssl"/></th>}
          </tr>
        </thead>
        <tbody className='theme-color'>
          {servers.map(({ host, ports, region, ssl_ports }: any) => {
            const copyText = `${host}`;
            return (
              <tr key={host}>
                <td className="flex items-center">
                  {copyText}
                  <Copy
                    text={copyText}
                    className="theme-color ml-0.5"
                    onCopyCallback={() => { 
                      sendGtmCustomEvent({
                        action: 'copy_server_click',
                        label: JSON.stringify({
                          server_region: region || '',
                          server_name: host || '',
                        }),
                      }); 
                    }}
                    title={intl.formatMessage({ id: 'copy' })}
                  />
                </td>
                <td>{ports[0]}</td>
                {isAtLeaseOneExtraPort && <td>{ports[1]}</td>}
                {isAtLeaseOneSSL && <td>{ssl_ports[0]}</td>}
              </tr>
            )
          })}
        </tbody>
      </table>
    </Box>
  );
}

export default MinerServerList;
