import { FormattedMessage, useIntl } from 'react-intl';
import _get from 'lodash/get';
import cx from 'classnames';
import { useParallax } from 'react-scroll-parallax';
import JoinButton from '@/widgets/Landing/shared/JoinButton';
import JoinButtonAccount from '@/widgets/Landing/btc/components/JoinButton';
import { useNavigation } from '@/processes/Router';
import { useMinerAccount } from "@/processes/MinerAccounts";
import s from './JoinSection.module.css';

type JoinSectionProps = {
  className?: string;
  titleKey?: string;
}

const JoinSectionGuest = (props: JoinSectionProps) => {
  const { 
    className,
    titleKey = 'footer.join.title',
  } = props;
  const { coin, coinTitle, isAccounting } = useNavigation();
  const intl = useIntl();
  const { ref: refParallax1 } = useParallax({ translateY: ['100px', '-100px'] });
  const { ref: refParallax2 } = useParallax({ translateY: ['-100px', '100px'] });
  const { ref: refParallax3 } = useParallax({ translateY: ['50px', '-50px'] });

  return (
    <section className={cx(className, s.root)}>
      <span ref={refParallax1} className={cx(s.coinImg, s.coinImg1, 'top-0 -left-20 md:-top-12 md:-left-44')} aria-hidden={true}/>
      <span ref={refParallax2} className={cx(s.coinImg, s.coinImg2, 'top-0 -right-24 md:-top-36 md:-right-56')} aria-hidden={true}/>
      <span ref={refParallax3} className={cx(s.coinImg, s.coinImg3, '-bottom-12 right-20 md:-bottom-24 md:right-40')} aria-hidden={true}/>
      <div className="mb-6 sm:mb-12">
        <h3 className={cx('landing-title')}>
          <FormattedMessage id={titleKey} values={{ coin, coinTitle, br: <br/>, span: (...chunks) => <span className="theme-text-highlight">{chunks}</span> }} />
        </h3>
      </div>

      <div className="text-center">
        {isAccounting ? (
          <JoinButtonAccount 
            title={intl.formatMessage({ id: 'l.btn.cta.v3' })}
            gtmBlock="tutorial"
            gtmButton="join_pool_now"
          />
        ) : (
          <JoinButton 
            title={intl.formatMessage({ id: 'l.btn.cta.v3' })}
            gtmBlock="tutorial"
            gtmButton="join_pool_now"
          />
        )}
      </div>
    </section>
  );
}

const JoinSection = (props: JoinSectionProps) => {
  const { isAuth } = useMinerAccount();
  if (isAuth) { return null; }
  return <JoinSectionGuest {...props}/>
};

export default JoinSection;
