import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'next/link';
import _get from 'lodash/get';
import cx from 'classnames';
import * as s from './FooterNavBlock.module.scss';
import { LINK_TYPES, DOMAIN_TYPES } from '~/Footer/constants/footer';
import { getIntlUrl } from '@/shared/utils/language';

const FooterNavBlock = (props) => {
  const {
    data,
    className = '',
    titlClass = '',
    children,
  } = props;
  const intl = useIntl();

  return (
    <div className={cx(s.root, className)}>
      <h4 className={cx(s.title, titlClass)}>
        <FormattedMessage id={data.title} />
      </h4>

      {
        children ? children : null
      }

      {
        data.items ? (
          <ul className={s.items}>
            {data.items.map((item, idx) => (
              <li className={s.item} key={`${data.title}-footer-item-${idx}`}>
                <Link
                  href={getIntlUrl(item, intl.locale)}
                  className={s.link}
                  {...(item.gtmParams || {})}
                  target="_blank"
                >
                  {/* {item.icon && (
                    <Icon name={item.icon} className="mr-1.5 inline-flex align-top w-[14px]"/>
                  )} */}
                  {
                    item.noIntl ? item.title : (
                      <FormattedMessage id={item.title} />
                    )
                  }
                </Link>
              </li>
            ))}
          </ul>
        ) : null
      }
    </div>
  );
}

export default FooterNavBlock;
