import React from "react";
import YouTube from 'react-youtube';
import _toLower from 'lodash/toLower';
import Modal from "@/shared/ui/Modal";

type VideoGuideModalProps = {
  youtubeId: string;
  className?: string;
  classNameContainer?: string;
  classNameCloseBtn?: string;
  start?: number;
  show?: boolean;
  onDismiss: () => void;
}

const VideoGuideModal = (props: VideoGuideModalProps) => {
  const {
    youtubeId,
    start = 0,
    show = false,
    className=  '',
    classNameContainer=  '',
    classNameCloseBtn=  '',
    onDismiss,
  } = props;
  const options = {
    height: '480',
    width: '100%',
    playerVars: {
      start,
      autoplay: 1,
    },
  };
  return (
    <Modal
      show={show} 
      onDismiss={onDismiss}
      size="xl"
      align="center"
      classNameContainer={classNameContainer}
      classNameCloseBtn={classNameCloseBtn}
    >
      <YouTube videoId={youtubeId} opts={options}/>
    </Modal>
  );
};

export default VideoGuideModal;
