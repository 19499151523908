import Table from "@/widgets/Landing/shared/StartMiningWith/components/Table";
import JoinButtonBTC from '@/widgets/Landing/btc/components/JoinButton'
import JoinButton from '@/widgets/Landing/shared/JoinButton'
import { animated } from 'react-spring';
import { useBlockAnimate } from '@/shared/hooks/useBlockAnimate';
import { useNavigation } from '@/processes/Router';
import s from "./StartMiningWith.module.scss";
import {FormattedMessage, useIntl} from "react-intl";
import cx from "classnames";
import Picture from "@/shared/ui/Picture";
import React from "react";
import _toLower from "lodash/toLower";
import { IconDynexHexagon, IconDynexInfinityWinds } from "@/shared/ui/Icons";

type PoolListProps = {
  className?: string;
  titleKey?: string;
}

const MINING_DNX_LIST = ['landing.dnx.startMiningWith.list.item1', 'landing.dnx.startMiningWith.list.item2', 'landing.dnx.startMiningWith.list.item3','landing.dnx.startMiningWith.list.item4', 'landing.dnx.startMiningWith.list.item5'];
const START_MINING_DNX_LIST = ['landing.dnx.startMiningWith.list2.item1', 'landing.dnx.startMiningWith.list2.item2', 'landing.dnx.startMiningWith.list2.item3','landing.dnx.startMiningWith.list2.item4', 'landing.dnx.startMiningWith.list2.item5'];

const StartMiningWith = (props: PoolListProps) => {
  const intl = useIntl();
  const { 
    className,
    titleKey = 'landing.startMiningWith.title',
  } = props;
  const { coin, coinTitle, isAccounting } = useNavigation();
  const coinImg = _toLower(coin);
  const { ref: ref1, springStyles: springTitleStyles1 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref2, springStyles: springTitleStyles2 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref3, springStyles: springTitleStyles3 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref4, springStyles: springTitleStyles4 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref5, springStyles: springTitleStyles5 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });

  return (
    <div className={cx(s.root, className)}>
      <span ref={ref1} className={cx(s.light, s.abstractLight1, '-top-2/4 -left-36 md:-top-48 md:-left-1/3')} aria-hidden={true}/>
      <span ref={ref2} className={cx(s.light, s.abstractLight2, '-top-1/4 -right-28 md:top-8 md:-right-1/4')} aria-hidden={true}/>

      <div className="flex flex-col md:flex-row items-center mb-12 md:mb-20">
        <div className="mr-4" ref={ref1}>
          <animated.h2 className="landing-title" style={springTitleStyles1}>
            <FormattedMessage id={titleKey} values={{
              coin,
              coinTitle,
              span: (...chunks) => <span className="theme-text-highlight">{chunks}</span>,
            }}/>
          </animated.h2>

          <animated.p ref={ref2} style={springTitleStyles2} className="mt-8 text-base sm:text-xl leading-7 max-w-[850px] sm:pl-1">
            <FormattedMessage id="landing.dnx.startMiningWith.desc" />
          </animated.p>
        </div>
        <div className="md:w-1/3 mt-12 md:mt-0 text-center">
          {isAccounting ? (
            <JoinButtonBTC title={intl.formatMessage({ id: 'landing.startMiningWith.joinButton' }, { coin })} />
          ) : (
            <JoinButton title={intl.formatMessage({ id: 'landing.startMiningWith.joinButton' }, { coin })} />
          )}
        </div>
      </div>

      <animated.div ref={ref3} style={springTitleStyles3} className="max-w-[788px] mx-auto mb-12 lg:mb-24">
        <Picture
          size={[682, 1266]}
          alt="Hiveon OS"
          src={`/assets/images/${coinImg}-start-mining.png`}
          medias={[
            {
              media: '(max-width: 768px)',
              src: `/assets/images/${coinImg}-hive-os-mobile.png`,
              size: [682, 1266],
            },
            {
              media: '(min-width: 769px)',
              src: `/assets/images/${coinImg}-start-mining.png`,
              size: [1752, 1040],
            },
          ]}
          locales={['en']}
          formats={['png']}
        />
      </animated.div>

      <animated.div ref={ref4} style={springTitleStyles4} className="flex flex-wrap justify-between gap-4 max-w-[788px] mx-auto mb-12 lg:mb-24">
        <div className={s.listWrapper}>
          { titleKey ?
            <div className={cx(s.listTitle)}>
              <FormattedMessage id="landing.dnx.startMiningWith.list.title" values={{
                span: (...chunks) => <span>{chunks}</span>,
              }}/>
            </div>
            : null
          }
          <ul className={cx(s.list)}>
            {MINING_DNX_LIST.map((messageId, index) => (
              <li key={index} className={cx(s.listItem)}>
                <IconDynexHexagon className={s.listIcon}/>
                {intl.formatMessage({id: messageId})}
              </li>
            ))}
          </ul>
        </div>

        <div className={s.listWrapper}>
          { titleKey ?
            <div className={cx(s.listTitle)}>
              <FormattedMessage id="landing.dnx.startMiningWith.list2.title" values={{
                span: (...chunks) => <span className="theme-text-highlight">{chunks}</span>,
              }}/>
            </div>
            : null
          }
          <ul className={cx(s.list)}>
            {START_MINING_DNX_LIST.map((messageId, index) => (
              <li key={index} className={cx(s.listItem)}>
                <IconDynexInfinityWinds className={s.listIcon}/>
                {intl.formatMessage({id: messageId})}
              </li>
            ))}
          </ul>
        </div>
      </animated.div>

      <animated.div ref={ref5} style={springTitleStyles5}>
        <Table className="mb-12 sm:mb-36" />
      </animated.div>
    </div>
  )}

export default StartMiningWith;
