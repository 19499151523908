import React from 'react';
import _get from "lodash/get";
import { useIntl } from "react-intl";
import cx from "classnames";
import { useNavigation } from '@/processes/Router';
import MinerInfo from '@/widgets/Landing/btc/components/MinerInfo';
import NetworkHashrateChart from '@/widgets/Landing/btc/components/NetworkHashrateChart';
import MinerServerList from '@/widgets/Landing/shared/MinerServerList';
import JoinButton from '@/widgets/Landing/btc/components/JoinButton'

type MinerBlocksProps = {
  className: string,
}
const MinerBlocks = (props: MinerBlocksProps) => {
  const {
    className,
  } = props;
  const intl = useIntl();
  const { coin } = useNavigation();

  return (
    <>
      <section className={cx(className)}>
        <div className={cx(className, 'flex flex-col sm:flex-row sm:-mx-3')}>
          <div className="sm:w-1/2 sm:px-3 mb-6">
            <NetworkHashrateChart className='p-4 sm:p-8'/>
          </div>
          <div className="sm:w-1/2 sm:px-3 mb-6">
            <MinerInfo className='p-4 sm:p-8 mb-6'/>
            <MinerServerList className='p-4 sm:p-8'/>
          </div>
        </div>

        <div className="flex justify-center items-center">
          <JoinButton title={intl.formatMessage({ id: 'l.btn.cta.v1' }, { coin })}/>
        </div>
      </section>
    </>
  );
}


export default MinerBlocks;
