import React from 'react';
import LoaderDots from "@/shared/ui/LoaderDots";
import cx from 'classnames';
import s from './ThemeButton.module.scss';

type ThemeButtonProps = {
  component?: 'button' | 'a';
  className?: string;
  onClick?: (...props: any) => void;
  disabled?: boolean;
  size?: 'sm' | 'xl';
  type?: 'button' | 'submit' | 'reset';
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  text?: string;
  colorSchema?: 'primary';
  isLoading?: boolean;
  isActive?: boolean;
  ref?: any;
  children?: React.ReactNode;
  [x:string]: any;
}

const ThemeButton = (props: ThemeButtonProps) => {
  const {
    className = '',
    onClick,
    disabled = false,
    component: Component = 'button',
    size = 'xl',
    type = 'button',
    text,
    colorSchema = 'primary',
    prefixIcon,
    suffixIcon,
    isLoading = false,
    isActive = false,
    children,
    ref,
    ...restProps
  } = props;

  const content = text || children;

  const sizes = {
    sm: s.sm,
    xl: s.xl,
  };

  const variants = {
    solid: s.solid,
    text: s.text,
    outline: s.outline,
  };

  const colorSchemes = {
    secondary: s.secondary,
    primary: s.primary,
  };

  const buttonClasses = cx(
    s.button,
    disabled && s.disabled,
    colorSchemes[colorSchema],
    'theme-btn',
  );

  const innerClasses = cx(
    s.inner,
    sizes[size],
  );

  const componentRestProps = Component === 'button' ? {
    'button': {
      type,
    },
  } : {};

  return (
    <Component
      type={type}
      disabled={disabled}
      className={cx(buttonClasses, className, {
        [s.isActive]: isActive,
        [s.isLoading]: isLoading,
      })}
      ref={ref}
      onClick={onClick}
      {...componentRestProps}
      {...restProps}
    >
      <span className={innerClasses}>
        {isLoading ? (
          <span className={s.loadingDots}>
            <LoaderDots isLoading isDark={false} />
          </span>
        ) : (
          <>
            {prefixIcon ? (
              <span className={s.prefix}>
                {prefixIcon}
              </span>
            ) : null}
            <span className={s.content}>{content}</span>
            {suffixIcon ? (
              <span className={s.suffix}>
                {suffixIcon}
              </span>
            ) : null}
          </>
        )}
      </span>
    </Component>
  );
}

export default ThemeButton;
