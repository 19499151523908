import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import s from './QuickStats.module.scss';
import { useNavigation } from "@/processes/Router";
import { getFormattedPower, getPower } from "@/shared/utils/formatters";
import { useProfitPerPower, useCurrencyWithExchange, useCoinsStats } from "@/processes/PoolStats";
import _get from "lodash/get";

type MiningMetricsProps = {
  className: string,
}
const QuickStats = (props: MiningMetricsProps) => {
  const { coinsStats } = useCoinsStats();
  const { coin, minimalHashrateToShow } = useNavigation();
  const { formatCurrency } = useCurrencyWithExchange({ coin })
  const { profitPerPower } = useProfitPerPower({ coin })

  const {
    className,
  } = props;

  const coinStats = coinsStats[coin];

  const profitPerPowerFormatted = getPower(profitPerPower, 0);
  const perPower = `${profitPerPowerFormatted.value} ${profitPerPowerFormatted.suffix}`;
  const exchangeRateUSD = _get(coinStats, 'exchangeRates.USD', 0);
  const expectedReward24H = _get(coinStats, 'expectedReward24H', 0);
  const hashrateProp = 'hashrate';
  const hashrate = _get(coinStats, hashrateProp, 0);
  const hashratePower = getFormattedPower(hashrate);
  const dailyEarnings = formatCurrency(expectedReward24H);

  return (
    <section className={className}>
      <ul className={s.list}>
        <li className={s.item}>
          <div className="sm:text-left text-center">
            <span className={s.itemLabel}><FormattedMessage id="Daily Earnings"/></span>
            <span className={cx(s.itemValue, 'theme-color')}>
              {dailyEarnings}{' '}/{' '}{perPower}
            </span>
          </div>
        </li>
        {/* Show pool hashrate if it's greater than defined */}
        {hashrate >= minimalHashrateToShow ? (
          <li className={s.item}>
            <div className="sm:text-left text-center">
              <span className={s.itemLabel}><FormattedMessage id="PoolHashrate"/></span>
              <span className={cx(s.itemValue, 'theme-color')}>{hashratePower}</span>
            </div>
          </li>
        ) : null}
        <li className={s.item}>
          <div className="sm:text-left text-center">
            <span className={s.itemLabel}>
              {coin}
                {' '}
                <span className="lowercase"><FormattedMessage id="Price"/></span>
            </span>
            <span className={cx(s.itemValue, 'theme-color')}>${exchangeRateUSD}</span>
          </div>
        </li>
        <li className={s.item}>
          <div className="sm:text-left text-center">
            <span className={s.itemLabel}><FormattedMessage id="rewardType.text"/></span>
            <span className={cx(s.itemValue, 'theme-color')}><FormattedMessage id="rewardType.PPS+"/></span>
          </div>
        </li>
      </ul>
    </section>
  );
}


export default QuickStats;
