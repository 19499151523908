import badgeAppleUs from '@/shared/assets/app/badge-appstore-en.svg';
import badgeAppleRu from '@/shared/assets/app/badge-appstore-ru.svg';
import badgeAppleZh from '@/shared/assets/app/badge-appstore-zh.svg';
import badgeGoogleUs from '@/shared/assets/app/badge-google-en.svg';
import badgeGoogleRu from '@/shared/assets/app/badge-google-ru.svg';
import badgeGoogleZh from '@/shared/assets/app/badge-google-zh.svg';
import Link from 'next/link';
import _get from 'lodash/get';
import cx from 'classnames';
import s from './StoreBadge.module.scss';

/**
 * NOTE: more images you can find by these urls:
 * Apple https://developer.apple.com/app-store/marketing/guidelines/
 * Android (you need to convert png into svg by yourself) https://play.google.com/intl/en_us/badges/
 */
const STORE_LOCALE_MAP = {
  apple: {
    en: {
      img: badgeAppleUs,
      url: 'https://apps.apple.com/us/app/id1521697503',
    },
    ru: {
      img: badgeAppleRu,
      url: 'https://apps.apple.com/ru/app/id1521697503',
    },
    zh: {
      img: badgeAppleZh,
      url: 'https://apps.apple.com/cn/app/id1521697503',
    },
  },
  google: {
    en: {
      img: badgeGoogleUs,
      url: 'https://play.google.com/store/apps/details?id=hiveos.mobile',
    },
    ru: {
      img: badgeGoogleRu,
      url: 'https://play.google.com/store/apps/details?id=hiveos.mobile',
    },
    zh: {
      img: badgeGoogleZh,
      url: 'https://play.google.com/store/apps/details?id=hiveos.mobile',
    },
  },
};

type StoraBadgeType = {
  type: string;
  locale: string;
  className?: string;
}

const StoreBadge = (props: StoraBadgeType) => {
  const {
    className = '',
    locale = 'en',
    type = '',
  } = props;

  if (!type) {
    return null;
  }

  // use 'en' version as a fallback
  const data = _get(STORE_LOCALE_MAP, [type, locale], null) || _get(STORE_LOCALE_MAP, [type, 'en'], {});
  const imgSrc = _get(data, 'img', '');
  const url = _get(data, 'url', '');

  return (
    <div className={cx(s.root, className)}>
      <Link href={url} target='_blank'>
        <img src={imgSrc.src} alt="store" />
      </Link>
    </div>
  );
}

export default StoreBadge;
