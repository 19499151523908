import { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import s from './AdvancedCalculator.module.scss';
import useCryptoCurrencies from "@/processes/PoolStats/hooks/useCryptoCurrencies";
import useCoinsStats from "@/processes/PoolStats/hooks/useCoinsStats";
import {useLocalizedUrl} from "@/shared/hooks/useLocalizedUrl";
import _get from 'lodash/get';
import _debounce from "lodash/debounce";
import _snakeCase from "lodash/snakeCase";
import _toLower from "lodash/toLower";
import {
  HR_UNITS_LIST,
  POWER_UNITS_LIST,
  DEFAULT_POWER_COST,
  DEFAULT_POWER_CONSUMPTION,
  POWER_MULTIPLY_MAP,
  HR_MULTIPLY_MAP,
  CURRENCY_RATE_MAP,
  CURRENCY_LIST
} from '~/ProfitCalculator/constants';
import {
  DEFULT_HR_UNITS,
  GPU_DEVICES
} from "~/ProfitCalculator/constants/devices";
import { getPower } from "@/shared/utils/formatters";
import { toFixed } from "@/shared/utils/toFixed";
import Link from "next/link";
import { useNavigation } from "@/processes/Router";
import Select from "@/shared/ui/Select";
import HardwareCounter from "~/ProfitCalculator/components/AdvancedCalculator/components/HardwareCounter";
import InputWithSelect from "~/ProfitCalculator/components/AdvancedCalculator/components/InputWithSelect";
import TableResult from "~/ProfitCalculator/components/AdvancedCalculator/components/TableResult";
import { sendGtmCustomEvent } from "@/processes/CookieBanner";
import cx from "classnames";

type AdvancedCalculatorProps = {

}
const AdvancedCalculator = (props: AdvancedCalculatorProps) => {
  const intl = useIntl();
  const { coin, coinLower, coinTitle, isBTC, isKAS, isRVN, isDNX, fee } = useNavigation();
  const { cryptoCurrencies } = useCryptoCurrencies();
  const { coinsStats } = useCoinsStats();

  const themeColor = isDNX ? 'theme-gradient-color' : 'theme-color';
  const feeMessage = isDNX ? 'landing.dnx.profit.calculator.advanced.hint.fee' : 'landing.profit.calculator.advanced.hint.fee';

  const cryptoCurrency = cryptoCurrencies.find((item: any) => item.name === coin);
  const coinStats = coinsStats[coin];

  const { localizeUrl } = useLocalizedUrl();

  const handleDebounceFn = (callback: any) => {
    if (typeof callback === 'function') {
      callback();
    }
  };
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []); // eslint-disable-line

  // TODO: based on locale detect currency. For ZH -> CNY, for EN -> USD, by default -> USD
  const currency = "USD";
  const exchangeRateUSD = _get(coinStats, `exchangeRates.${currency}`, 0);
  const expectedReward24H = _get(coinStats, 'expectedReward24H', 0);
  const hardwareList = _get(GPU_DEVICES, coin, []);
  const defaultHrList = HR_UNITS_LIST[isBTC ? 'btc' : 'default'];
  const defaultHrUnit = _get(DEFULT_HR_UNITS, coin, "mhs");
  const defaultHrUnitItem = defaultHrList.find(i => i.value === defaultHrUnit) || null;
  const profitPerPower = _get(cryptoCurrency, 'profitPerPower', 0);
  const { value: profitValue, suffix: profitSuffix } = getPower(profitPerPower, 0);
  const expectedReward24HPer1HRUnit = expectedReward24H / Number(profitValue);
  const defaultHashrateValue = profitValue;

  const [calculated, setCalculated] = useState({});
  const [hardware, setHardware] = useState(null);
  const [unitsAmount, setUnitsAmount] = useState(1);
  const [hashrate, setHashrate] = useState(defaultHashrateValue);
  const [hashrateUnits, setHashrateUnits] = useState(defaultHrUnitItem);
  const [powerConsumption, setPowerConsumption] = useState(DEFAULT_POWER_CONSUMPTION);
  const [powerConsumptionUnits, setPowerConsumptionUnits] = useState(POWER_UNITS_LIST[0]);
  const [powerCost, setPowerCost] = useState(DEFAULT_POWER_COST);
  const [powerCostUnits, setPowerCostUnits] = useState(CURRENCY_LIST[0]);
  const [tableHrLabel, setTableHrLabel] = useState("");

  const handleHardwareChange = (value: any, skipGTM = false) => {
    setHardware(value);
    setHashrate(parseFloat(_get(value, "hashrate", 0)));
    setPowerConsumption(_get(value, "power", 0));
    setHashrateUnits(defaultHrUnitItem);
    setPowerConsumptionUnits(POWER_UNITS_LIST[0]);

    if (!skipGTM) {
      sendGtmCustomEvent({
        category: 'site_pool',
        action: 'choose_hardware_click',
        label: JSON.stringify({
          hardware_selected: value?.label || '',
        }),
      });
    }
  };
  const handlePowerCostUnitsChange = (value: any) => {
    setPowerCostUnits(value);
  };
  const handleUnitsAmountChange = (e: any) => {
    const count = parseInt(e.target.value) || 1;
    setUnitsAmount(count);
    debounceFn(() => {
      // gtmHardwareCountAdjust('manual_input', count);
    });
  };
  const handleAddCount = () => {
    const newValue = unitsAmount + 1;
    const count = isNaN(newValue) ? 1 : newValue;
    setUnitsAmount(count);
    // gtmHardwareCountAdjust('increase', count);
  };
  const handleSubtractCount = () => {
    const newValue = unitsAmount - 1;
    const count = isNaN(newValue) || newValue < 0 ? 0 : newValue;
    setUnitsAmount(count);
    // gtmHardwareCountAdjust('decrease', count);
  };
  const handleHashrateChange = (value: any) => {
    setHashrate(parseFloat(value));
    debounceFn(() => {
      sendGtmCustomEvent({
        category: 'site_pool',
        action: 'total_hashrate_adjust',
        label: JSON.stringify({
          total_hashrate: value,
        }),
      })
    });
  };
  const handleHashrateUnitsChange = (value: any) => {
    setHashrateUnits(value);
    sendGtmCustomEvent({
      category: 'site_pool',
      action: 'total_hashrate_unit_adjust',
      label: JSON.stringify({
        total_hashrate_unit: _snakeCase(value.label),
      }),
    });
  };
  const handlePowerConsumptionChange = (value: any) => {
    setPowerConsumption(value);
    debounceFn(() => {
      sendGtmCustomEvent({
        category: 'site_pool',
        action: 'power_consumption_adjust',
        label: JSON.stringify({
          power_consumption: value,
        }),
      });
    });
  };
  const handlePowerCostChange = (value: any) => {
    setPowerCost(value);
    debounceFn(() => {
      sendGtmCustomEvent({
        category: 'site_pool',
        action: 'electricity_cost_adjust',
        label: JSON.stringify({
          electricity_cost: value,
        }),
      });
    });
  };
  const handlePowerConsUnitsChange = (value: any) => {
    setPowerConsumptionUnits(value);
    sendGtmCustomEvent({
      category: 'site_pool',
      action: 'power_consumption_unit_adjust',
      label: JSON.stringify({
        power_consumption_unit: _toLower(value?.label || ''),
      }),
    });
  };
  const handleCalculate = () => {
    const hrMultiply = (HR_MULTIPLY_MAP[coinLower] || HR_MULTIPLY_MAP['default'])[_get(hashrateUnits, 'value', '')];
    const cryptoDay = (expectedReward24HPer1HRUnit) * Number(hashrate) * hrMultiply * unitsAmount;
    const fiatDay = cryptoDay * exchangeRateUSD;
    const dayElectricCost =
      parseFloat(String(powerConsumption)) *
      24 *
      POWER_MULTIPLY_MAP[powerConsumptionUnits.value] *
      parseFloat(String(powerCost)) *
      CURRENCY_RATE_MAP[powerCostUnits.value] *
      unitsAmount;

    setTableHrLabel(`${toFixed(Number(hashrate) * unitsAmount, 2)} ${_get(hashrateUnits, 'label', '')}`);

    const result = {
      day: {
        fiat: fiatDay,
        crypto: cryptoDay,
        electricCost: dayElectricCost,
        profit: fiatDay - dayElectricCost,
      },
      month: {
        fiat: fiatDay * 30,
        crypto: cryptoDay * 30,
        electricCost: dayElectricCost * 30,
        profit: fiatDay * 30 - dayElectricCost * 30,
      },
    };
    setCalculated(result);
  };

  useEffect(() => {
    handleHardwareChange(hardwareList[0], true);
  }, [coin]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleCalculate();
  }, [hardware, unitsAmount, hashrate, hashrateUnits, powerConsumption, powerConsumptionUnits, powerCost, powerCostUnits]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={s.root}>
      {isDNX ?
        <div className={cx(s.headDNX, 'lg:pr-5')}>
          <h2 className={s.title}>
            <FormattedMessage id="landing.dnx.profit.calculator.advanced.title" />
          </h2>
          <div className={s.desc}>
            <FormattedMessage id="landing.dnx.profit.calculator.advanced.desc" />
          </div>
        </div>
        :
        <div className={s.head}>
          <div className={s.title}>
            <FormattedMessage id="landing.profit.calculator.advanced.title" />
          </div>
          <Link
            href={localizeUrl(
              "https://hiveon.com/knowledge-base/hiveon-pool/hiveon_payouts_faq/payouts_faq/#how-are-payments-made-and-in-what-amount"
            )}
            target="_blank"
            className={s.linkQuestion}
            data-gtm-category="site_pool"
            data-gtm-action="how_it_is_calculated_click"
          >
            ⓘ <FormattedMessage id="landing.profit.calculator.advanced.question" />
          </Link>
        </div>
      }

      <div className="flex flex-col my-6 md:my-12 lg:flex-row">
        <div className="lg:pr-5 pb-5 lg:pb-0 lg:border-r lg:border-solid flex-1 border-white/[0.1]">
          <div className="flex mb-5 sm:mb-7 items-end relative z-10">
            <Select
              label={intl.formatMessage({id: 'landing.profit.calculator.advanced.select.сhooseHw'})}
              className="mr-[22px] flex-grow"
              selectClassName="mb-[1px]"
              labelTextClassName="text-base pb-4 font-normal"
              options={hardwareList}
              value={hardware}
              onChange={handleHardwareChange}
              placeholder={`-- ${intl.formatMessage({
                id: "landing.profit.calculator.advanced.placeholder.selectHw",
              })} --`}
              size="lg"
              textSize="base"
              full
              menuFull
              withCoinTheme
            />

            <HardwareCounter
              handleSubtractCount={handleSubtractCount}
              handleUnitsAmountChange={handleUnitsAmountChange}
              unitsAmount={unitsAmount}
              handleAddCount={handleAddCount}
            />
          </div>

          <div className="mb-0 sm:mb-7 flex flex-col sm:flex-row items-start sm:items-center relative gap-6 sm:gap-5">
            <InputWithSelect
              label={intl.formatMessage({id: "landing.profit.calculator.advanced.select.totalHr"})}
              id="hashrate"
              labelTextClassName="text-base mb-4 font-normal"
              inputValue={hashrate}
              defaultInputValue="0"
              inputOnChange={handleHashrateChange}
              selectOptions={defaultHrList}
              selectValue={hashrateUnits}
              selectOnChange={handleHashrateUnitsChange}
              full
            />

            <InputWithSelect
              label={intl.formatMessage({id: "landing.profit.calculator.advanced.select.powerCons"})}
              id="power"
              labelTextClassName="text-base mb-4 font-normal"
              inputValue={powerConsumption}
              defaultInputValue="0"
              inputOnChange={handlePowerConsumptionChange}
              selectOptions={POWER_UNITS_LIST}
              selectValue={powerConsumptionUnits}
              selectOnChange={handlePowerConsUnitsChange}
              full
            />

            <InputWithSelect
              label={intl.formatMessage({id: "landing.profit.calculator.advanced.select.elCost"})}
              id="cost"
              inputValue={powerCost}
              labelTextClassName="text-base mb-4 font-normal"
              defaultInputValue="0.00"
              min={0}
              step={0.01}
              inputOnChange={handlePowerCostChange}
              selectOptions={CURRENCY_LIST}
              selectValue={powerCostUnits}
              selectOnChange={handlePowerCostUnitsChange}
              full
            />
          </div>

          {isRVN &&
            <div className={s.actions}>
              <span className="text-3xl">
                <FormattedMessage id="landing.profit.calculator.advanced.hint.countUSD" values={{
                  count: '2',
                }}/>
              </span> {' '}
              <span className="text-base">
                  <FormattedMessage id="landing.profit.calculator.advanced.hint.perGPUMiner" />
              </span>
            </div>
          }
          {fee ? (
            <div className={s.actions}>
              <div className="text-sm text-[#EEF0F2]">
                <FormattedMessage id={feeMessage} values={{
                  coin,
                  coinTitle,
                  fee,
                  span: (...chunks) => <span className={themeColor}>{chunks}</span>,
                }}/>
              </div>
            </div>
          ) : null}
        </div>

        {/*RESULT TABLE*/}
        <TableResult
          tableHrLabel={tableHrLabel}
          currency={currency}
          calculated={calculated}
          exchangeRateUSD={exchangeRateUSD}
        />
      </div>
    </div>
  );
}

export default AdvancedCalculator;
