interface Unit {
  value: string;
  label: string;
}

interface MultiplyMap {
  [key: string]: number;
}

interface HRMultiplyMap {
  [key: string]: MultiplyMap;
}

export const HR_UNITS_LIST: Record<string, Unit[]> = {
  default: [
    {
      value: 'mhs',
      label: 'MH/s',
    },
    {
      value: 'ghs',
      label: 'GH/s',
    },
    {
      value: "ths",
      label: "TH/s",
    },
  ],
  btc: [
    {
      value: 'ghs',
      label: 'GH/s',
    },
    {
      value: "ths",
      label: "TH/s",
    },
    {
      value: "phs",
      label: "PH/s",
    },
    {
      value: "ehs",
      label: "EH/s",
    },
  ],
};

export const POWER_UNITS_LIST: Unit[] = [
  {
    value: "w",
    label: "W",
  },
  {
    value: "kw",
    label: "kW",
  },
  {
    value: "mw",
    label: "MW",
  },
];

export const POWER_MULTIPLY_MAP: MultiplyMap = {
  w: 0.001,
  kw: 1,
  mw: 1000,
};

export const CURRENCY_RATE_MAP: MultiplyMap = {
  USD: 1,
  RUB: 0.013,
};

export const HR_MULTIPLY_MAP: HRMultiplyMap = {
  default: {
    'mhs': 1,
    'ghs': 1e3,
    'ths': 1e6,
    'phs': 1e9,
    'ehs': 1e12,
  },
  btc: {
    'mhs': 0.00001,
    'ghs': 0.001,
    'ths': 1,
    'phs': 1e3,
    'ehs': 1e6,
  },
  kas: {
    'mhs': 0.001,
    'ghs': 1,
    'ths': 1e3,
    'phs': 1e6,
    'ehs': 1e9,
  },
  dnx: {
    'mhs': 0.001,
    'ghs': 1,
    'ths': 1e3,
    'phs': 1e6,
    'ehs': 1e9,
  },
};

export const DEFAULT_POWER_CONSUMPTION: number = 300;
export const DEFAULT_POWER_COST: number = 0.03;

export const CURRENCY_LIST: Unit[] = [
  {
    value: 'USD',
    label: 'USD',
  },
];
