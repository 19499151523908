import Link from "next/link";
import { FormattedMessage } from 'react-intl'
import cx from 'classnames';
import s from './TutorialLink.module.scss';

type TutorialLinkProps = {
  link?: string;
  className?: string;
  target?: string;
  icon?: 'tutorial' | 'video';
  [x:string]: any;
}

const TUTORIAL_LABEL = {
  'tutorial': 'tutorialLink.text',
  'video': 'tutorialLink.video',
}

const TutorialLink = (props: TutorialLinkProps) => {
  const {
    link = "https://hiveon.com/knowledge-base/",
    className = "",
    target = "_blank",
    icon = 'tutorial',
    ...restProps
  } = props;

  return (
    <Link href={link} {...restProps} className={cx(s.btn, className)} target={target}>
      <span className={cx(s.btnIcon, s[icon], 'theme-tutorial-btn', icon)} aria-hidden={true} />
      <FormattedMessage id={TUTORIAL_LABEL[icon]}/>
    </Link>
  );
};

export default TutorialLink;
