import { FormattedMessage } from "react-intl";
import cx from "classnames";

type SupportProps = {
  className?: string;
}

const Support = (props: SupportProps) => {
  const {
    className
  } = props;
  return (
    <div className={cx("flex items-center sm:items-start flex-col sm:flex-row", className)}>
      <div className="sm:mr-10">
        <span className="theme-support" aria-hidden={true} />
      </div>
      <div className="flex-grow-1">
        <h2 className="text-4/5xl text-center sm:text-left sm:text-8xl font-bold my-5 sm:mt-0 sm:mb-8">
          <FormattedMessage id="landing.support.title" />
        </h2>
        <p className="text-base sm:text-xl leading-7">
          <FormattedMessage id="landing.support.desc" values={{
            br: <br/>,
          }}/>
        </p>
      </div>
    </div>
  );
};

export default Support;
