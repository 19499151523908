import React from "react";
import { FormattedMessage } from "react-intl";
import cx from "classnames";
import s from "./Banner.module.scss";

type AdvantagesProps = {
  children?: React.ReactNode;
}

const Banner = (props: AdvantagesProps) => {
  const { children } = props;

  return (
    <>
      <div className={cx(s.root)}>
        <div className="inner">
          {children}
        </div>
      </div>
    </>
  );
};

export default Banner;
