import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import s from './AddressExplorer.module.scss';
import ThemeButton from '@/shared/ui/ThemeButton';
import { SelectCoin } from "~/Header";
import _get from 'lodash/get';
import { IconSearch } from '@/shared/ui/Icons'
import { POOLS, useNavigation } from "@/processes/Router";
import { useMinerAccount, addToRecentSearches } from "@/processes/MinerAccounts";
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import RecentlySearched from "@/widgets/Landing/shared/AddressExplorer/components/RecentlySearched";

type AddressExplorerProps = {
  className?: string;
}

const selectStyles = {
  control: (base :any) => ({
    ...base,
    width: '100px !important',
    border:'none !important',
    boxShadow: 'none !important',
    borderTopLeftRadius: '0.75rem !important',
    borderBottomLeftRadius: '0.75rem !important',
    borderTopRightRadius: '0 !important',
    borderBottomRightRadius: '0 !important',
    backgroundColor: 'rgba(255, 255, 255, 0.04) !important',
  }),
};

const schema = z.object({
  address: z.string(),
});
type ValidationSchema = z.infer<typeof schema>;

const AddressExplorer = (props: AddressExplorerProps) => {
  const {
    className = '',
  } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(false)
  const [isRecentlyResearch, setIsRecentlyResearch] = useState(false)
  const { coin, coinTitle, getPoolDashboardLink, navigate } = useNavigation()
  const { isMinerAddressValid } = useMinerAccount();
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(schema),
  });

  const handleOnSubmit = (data: any) => {
    const address = data.address;
    // check if address is valid
    if (!isMinerAddressValid(address)) {
      setError('address', { message: intl.formatMessage({ id: 'error.invalid.address' }) })
      return;
    }

    // store to recent searches
    dispatch(addToRecentSearches({ coin, address }));

    // navigate to dashboard
    setIsLoading(true);
    const dashboardLink = getPoolDashboardLink({ miner: address, coin });
    navigate(dashboardLink);
  }

  const handleBlur = (e: any) => {
    // Wait for the next tick to get the updated active element
    setTimeout(() => {
      // @ts-ignore
      if (!document.activeElement || !ref?.current?.contains(document.activeElement)) {
        setIsRecentlyResearch(false);
      }
    }, 100); // Added delay to fix Safari issue
  };

  const handleKeyDown = (e: any) => {
    if(e.keyCode === 27) {
      setIsRecentlyResearch(false)
    }
  };

  return (
    <div
      ref={ref}
      className={cx(s.root, className)}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
    >
      <div className="mb-5 text-white/[0.6]">{coinTitle} <FormattedMessage id="MinerAddress" /></div>

      <form
        className={s.inputGroup}
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <SelectCoin
          selectStyles={selectStyles} size="lg"
          filter={(coin: string) => {
            return _get(POOLS, [coin, 'showAddressExplorer'], false);
          }}
        />
        <input
          autoComplete='off'
          {...register('address')}
          className={s.input}
          onFocus={() => ( setIsRecentlyResearch(true) )}
        />
        <div className="w-44 relative z-10">
          <ThemeButton isLoading={isLoading} type='submit' className={cx(s.button, 'theme-explore-btn')}>
            <IconSearch className="sm:hidden"/>
            <span className='hidden sm:block'><FormattedMessage id="landing.addressExplorer.button" /></span>
          </ThemeButton>
        </div>
      </form>

      {isRecentlyResearch && <RecentlySearched />}
      {errors?.address?.message ? (
        <span className='text-xs mt-1.5 text-red-700 block'>{errors.address.message}</span>
      ): null}
    </div>
  );
}

export default AddressExplorer;
