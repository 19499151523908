export const LOCALIZED_LINKS = {
  'https://hiveon.com/asic': {
    zh: 'https://hiveon.com/zh/asic',
  },
  'https://hiveon.com/knowledge-base/hiveon-pool/how-to-start-mining-on-hiveon-btc-pool/': {
    zh: 'https://hiveon.com/knowledge-base/hiveon-pool/how-to-start-mining-on-hiveon-btc-pool/',
  },
  'https://hiveon.com/knowledge-base/hiveon-pool/how-to-start-mining-on-hiveon-gram-pool/': {
    zh: 'https://hiveon.com/knowledge-base/hiveon-pool/how-to-start-mining-on-hiveon-gram-pool/',
  },
  'https://hiveon.com/install/': {
    zh: 'https://hiveon.com/zh/install/',
  },
  'https://the.hiveos.farm/register/?lang=en': {
    zh: 'https://the.hiveos.farm/register/?lang=zh',
  },
  'https://hiveon.com/knowledge-base/hiveon-pool/hiveon_payouts_faq/payouts_faq/': {
    // ZH version is not available, fallback to en
  },
  'https://hiveon.com/knowledge-base/hiveon-pool/general_pool_faq/general_faq/#what-is-vardiff-and-why-is-it-needed': {
    // ZH version is not available, fallback to en
  },
  'https://hiveon.com/knowledge-base/hiveon-pool/hiveon_payouts_faq/payouts_faq/#how-are-payments-made-and-in-what-amount': {
    // ZH version is not available, fallback to en
  },
  'https://hiveon.com/knowledge-base/': {
    zh: 'https://hiveon.com/zh/knowledge-base/',
  },
  'https://hiveon.com/knowledge-base/hiveon-pool/hiveon-setting-up/': {
    // ZH version is not available, fallback to en
  },
  'https://hiveon.com/knowledge-base/hiveon-pool/pool_rules/': {
    // ZH version is not available, fallback to en
  },
  'https://hiveon.com/knowledge-base/hiveon-pool/': {
    // ZH version is not available, fallback to en
  },
}
