import { FormattedMessage } from 'react-intl';
import { sendGtmCustomEvent } from '@/processes/CookieBanner';
import s from './ScrollToGameRvn.module.scss';
import { IconArrowDown } from "@/shared/ui/Icons";
import { GAME_ID } from "@/widgets/Landing/rvn/components/Game/constants/flappy_bird";
import { useEffect, useState } from "react";
import { readFromStorage, writeToStorage } from "@/shared/utils/storage";

type ScrollToGameRvnProps = {
  inView: boolean;
}

const storageName = 'game.flappyBirdScrollIcon';

const ScrollToGameRvn = (props: ScrollToGameRvnProps) => {
  const { inView } = props;

  const [isShowScroll, setIsShowScroll] = useState(true);

  useEffect(() => {
    setIsShowScroll(readFromStorage(storageName, false));
  }, [])

  useEffect(() => {
    if (!isShowScroll && inView) {
      writeToStorage(storageName, true)
      setIsShowScroll(true);
    }
  }, [inView, isShowScroll])

  const handleScrollToGame = () => {
    sendGtmCustomEvent({
      category: 'site_pool',
      action: 'rvn_game_play_click',
      label: JSON.stringify({
        button: 'play_a_game',
      }),
    });

    const container = document.querySelector(`#${GAME_ID}`)

    if (container) {
      container.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      writeToStorage(storageName, true);
      setIsShowScroll(true);
    }
  }

  return (
    !isShowScroll ? (
      <button
        type="button"
        className={s.root}
        aria-hidden="true"
        onClick={handleScrollToGame}
      >
        <span className={s.gameIcon}/>
        <span className={s.gameLabel}>
            <FormattedMessage id="Play game"/>
          </span>
        <IconArrowDown className={s.scrollArrow}/>
      </button> )
      :
      null
  );
}

export default ScrollToGameRvn;
