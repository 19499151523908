import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { useNavigation } from "@/processes/Router";
import { usePoolStats } from "@/processes/PoolStats";
import { getPower, getPercent } from '@/shared/utils/formatters';
import { humanizeDuration } from '@/shared/utils/humanizeDuration';
import _get from "lodash/get";
import _first from "lodash/first";
import Box from '@/shared/ui/Box'
import s from "./MinerInfo.module.scss";
import { useGetBTCNetworkHashrateQuery } from '@/widgets/Landing/btc/components/NetworkHashrateChart';

type MinerInfoProps = {
  className: string,
}
const MinerInfo = (props: MinerInfoProps) => {
  const {
    className,
  } = props;

  const { coin, locale, fee } = useNavigation();
  const { data = {} } = useGetBTCNetworkHashrateQuery(null);

  const coinStats = usePoolStats({ coin });
  const threshold = _get(coinStats, 'threshold', 0);
  const nextDiffChangePerc = _get(data, 'next_diff_change_perc', '+0.00%');
  const isNextDiffChangePercUp = nextDiffChangePerc.includes('+');
  const currentDificulty = _get(data, 'current_difficulty', 0);
  const nextDificultyRel = _get(data, 'next_diff_change_rel', 0);
  const nextDificultyPower = getPower(currentDificulty + nextDificultyRel);
  const currentDificultyPower = getPower(currentDificulty);
  const nextDiffInMinutes = _get(data, 'next_diff_change_in_minutes', 0);
  const nextDiffInMs = nextDiffInMinutes * 60 * 1000;
  const nextDiffHumanized = nextDiffInMs ? humanizeDuration(nextDiffInMs, { units: ["d", "h"], delimiter: " ", round: true, language: locale }) : '~';

  const giversDiff = '562.95G';
  
  return (
    <Box className={cx(className, 'h-full')} size='none'>
      <div className={cx(s.row)}>
        <div className={cx(s.item)}>
          <span className={cx(s.itemLabel)}><FormattedMessage id="miner.givers.diff"/></span>
          <span className={cx(s.itemValue, 'theme-color')}>
            {giversDiff}
          </span>
        </div>

        <div className={cx(s.item)}>
          <span className={cx(s.itemLabel)}><FormattedMessage id="miner.pool.fee"/></span>
          <span className={cx(s.itemValue, 'theme-color')}>
            {getPercent(fee)}
          </span>
        </div>

        <div className={cx(s.item)}>
          <span className={cx(s.itemLabel)}><FormattedMessage id="miner.payment.time"/></span>
          <span className={cx(s.itemValue, 'theme-color')}><FormattedMessage id="miner.gram.payout"/> UTC</span>
        </div>
        <div className={cx(s.item)}>
          <span className={cx(s.itemLabel)}><FormattedMessage id="miner.threshold"/></span>
          <span className={cx(s.itemValue, 'theme-color')}>{threshold} {coin}</span>
        </div>
      </div>
    </Box>
  );
}

export default MinerInfo;
