import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { useNavigation } from "@/processes/Router";
import { usePoolStats, useCryptoCurrency } from "@/processes/PoolStats";
import _get from "lodash/get";
import Box from '@/shared/ui/Box'
import s from "./MinerInfo.module.scss";

type MinerInfoProps = {
  className: string,
}
const MinerInfo = (props: MinerInfoProps) => {
  const {
    className,
  } = props;

  const { coin, isKAS } = useNavigation();
  const cryptoCurrency = useCryptoCurrency({ coin });
  const coinStats = usePoolStats({ coin });
  
  const payoutAt = _get(cryptoCurrency, 'payoutAt', 0);
  const threshold = _get(coinStats, 'threshold', 0);
  const blocksFound = _get(coinStats, 'blocksFound', 0);

  return (
    <Box className={cx(className)} size='none'>
      <div className={cx(s.row, {[s.isKas]: isKAS})}>
        {!isKAS ?
          <div className={cx(s.item)}>
            <span className={cx(s.itemLabel)}><FormattedMessage id="miner.blocks"/></span>
            <span className={cx(s.itemValue, 'theme-color')}>{blocksFound}</span>
          </div>
          :
          null
        }
        <div className={cx(s.item)}>
          <span className={cx(s.itemLabel)}><FormattedMessage id="miner.payment.time"/></span>
          <span className={cx(s.itemValue, 'theme-color')}>{payoutAt} UTC</span>
        </div>
        <div className={cx(s.item)}>
          <span className={cx(s.itemLabel)}><FormattedMessage id="miner.threshold"/></span>
          <span className={cx(s.itemValue, 'theme-color')}>{threshold} {coin}</span>
        </div>
      </div>
    </Box>
  );
}


export default MinerInfo;
