import React from "react"

export const Facebook = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 20 21"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="m14.551 12.154.544-3.62h-3.397V6.186c0-.99.474-1.956 1.996-1.956h1.544V1.149S13.837.904 12.497.904C9.7.904 7.87 2.638 7.87 5.776v2.759h-3.11v3.62h3.11v8.75h3.827v-8.75l2.853-.001Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .904)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const Blog = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 20 21"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10.904c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10-10-4.477-10-10Zm12.012 5.556a3.532 3.532 0 0 0 3.522-3.505l.022-2.837-.034-.154-.093-.194-.157-.122c-.09-.071-.344-.077-.622-.084-.351-.008-.742-.017-.897-.159-.185-.168-.224-.463-.277-.862a44.947 44.947 0 0 0-.012-.087c-.096-.74-.161-.875-.264-1.085a5.264 5.264 0 0 1-.052-.108c-.487-1.032-1.81-1.807-2.72-1.914H7.966c-1.938 0-3.522 1.58-3.522 3.51v4.096c0 1.927 1.584 3.505 3.522 3.505h4.046ZM8.01 9.559h1.952a.676.676 0 0 0 .675-.672.674.674 0 0 0-.675-.67H8.011a.674.674 0 0 0-.675.67c0 .367.302.672.675.672Zm3.967 2.676H8.011c-.373 0-.675.3-.675.668 0 .363.302.668.675.668h3.967c.37 0 .671-.305.671-.668a.67.67 0 0 0-.671-.668Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .904)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const Discord = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 20 21"
    {...props}
  >
    <path
      d="M16.93 4.525a16.452 16.452 0 0 0-4.07-1.267.062.062 0 0 0-.066.031c-.175.314-.37.723-.506 1.045a15.177 15.177 0 0 0-4.573 0c-.136-.329-.338-.73-.515-1.045a.064.064 0 0 0-.065-.03c-1.428.246-2.795.679-4.07 1.266a.058.058 0 0 0-.028.023C.444 8.434-.266 12.225.083 15.97a.069.069 0 0 0 .026.047 16.565 16.565 0 0 0 4.994 2.533.065.065 0 0 0 .07-.023c.385-.527.728-1.083 1.022-1.668a.064.064 0 0 0-.035-.088 10.907 10.907 0 0 1-1.56-.746.064.064 0 0 1-.007-.107c.105-.079.21-.16.31-.243a.061.061 0 0 1 .065-.01c3.273 1.5 6.817 1.5 10.051 0a.061.061 0 0 1 .066.009c.1.083.204.165.31.244a.064.064 0 0 1-.005.107c-.499.292-1.017.539-1.561.745a.064.064 0 0 0-.034.09c.3.583.643 1.139 1.02 1.666a.063.063 0 0 0 .07.024 16.51 16.51 0 0 0 5.003-2.533.065.065 0 0 0 .026-.046c.417-4.328-.699-8.088-2.957-11.421a.05.05 0 0 0-.026-.024ZM6.684 13.69c-.985 0-1.797-.907-1.797-2.022 0-1.115.796-2.022 1.797-2.022 1.01 0 1.814.915 1.798 2.022 0 1.115-.796 2.022-1.798 2.022Zm6.646 0c-.986 0-1.797-.907-1.797-2.022 0-1.115.796-2.022 1.797-2.022 1.009 0 1.813.915 1.797 2.022 0 1.115-.788 2.022-1.797 2.022Z"
      fill="currentColor"
    />
  </svg>
);

export const Github = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 20 21"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.013 1.395A10.147 10.147 0 0 0 3.524 3.72 9.715 9.715 0 0 0 .13 9.6a9.578 9.578 0 0 0 1.319 6.625 9.963 9.963 0 0 0 5.402 4.209c.498.09.682-.216.682-.474v-1.661c-2.782.6-3.371-1.307-3.371-1.307a2.622 2.622 0 0 0-1.111-1.433c-.903-.6.073-.6.073-.6.317.044.619.157.884.332.265.174.485.405.645.675.136.24.32.453.54.624a2.186 2.186 0 0 0 2.371.192c.038-.493.256-.957.614-1.307-2.223-.246-4.556-1.086-4.556-4.827a3.728 3.728 0 0 1 1.025-2.621 3.479 3.479 0 0 1 .098-2.584s.842-.264 2.751 1c1.64-.439 3.37-.439 5.01 0 1.91-1.264 2.745-1 2.745-1 .368.817.405 1.74.105 2.584a3.729 3.729 0 0 1 1.025 2.62c0 3.754-2.34 4.576-4.568 4.798.239.234.423.516.541.827.118.31.165.642.14.972v2.68c0 .318.178.564.688.474a9.968 9.968 0 0 0 5.381-4.217 9.584 9.584 0 0 0 1.308-6.616 9.72 9.72 0 0 0-3.386-5.87 10.152 10.152 0 0 0-6.471-2.336v.036Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .904)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const Hive = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 20 21"
    {...props}
  >
    <g clipPath="url(#a)" fill="currentColor">
      <path d="M2.68 4.564A3.66 3.66 0 0 0 .85 7.735v6.339a3.66 3.66 0 0 0 1.831 3.168L4.512 18.3V3.508L2.68 4.564ZM17.32 4.564l-1.832-1.056V18.3l1.832-1.057a3.663 3.663 0 0 0 1.831-3.168v-6.34a3.663 3.663 0 0 0-1.831-3.172ZM10 12.245a3.644 3.644 0 0 0-1.832.49v7.68a3.663 3.663 0 0 0 3.66 0v-7.68a3.644 3.644 0 0 0-1.828-.49ZM8.17 1.395v7.677a3.663 3.663 0 0 0 3.66 0V1.395a3.66 3.66 0 0 0-3.66 0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .904)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const Telegram = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 20 21"
    {...props}
  >
    <path
      d="M18.65 2.628.934 9.46c-1.21.485-1.202 1.16-.222 1.46l4.549 1.42L15.784 5.7c.498-.303.952-.14.579.191l-8.527 7.695h-.002l.002.001-.313 4.689c.46 0 .662-.211.92-.46l2.21-2.148 4.595 3.394c.847.467 1.456.227 1.666-.784L19.931 4.06c.309-1.238-.473-1.799-1.281-1.433Z"
      fill="currentColor"
    />
  </svg>
);

export const Twitter = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 20 21"
    {...props}
  >
    <path
      d="M20 4.226a8.077 8.077 0 0 1-2.363.697 4.348 4.348 0 0 0 1.804-2.44 7.848 7.848 0 0 1-2.6 1.069c-.752-.863-1.825-1.398-2.995-1.398-2.27 0-4.097 1.985-4.097 4.417 0 .35.027.687.095 1.007-3.409-.18-6.425-1.939-8.451-4.619A4.71 4.71 0 0 0 .83 5.191c0 1.53.732 2.885 1.821 3.67A3.83 3.83 0 0 1 .8 8.317v.048c0 2.146 1.421 3.928 3.285 4.34a3.816 3.816 0 0 1-1.075.145c-.262 0-.527-.017-.776-.076.531 1.749 2.038 3.034 3.831 3.076-1.395 1.175-3.166 1.883-5.084 1.883-.336 0-.658-.016-.981-.06a10.953 10.953 0 0 0 6.29 1.981c7.545 0 11.67-6.73 11.67-12.565 0-.195-.006-.383-.015-.57A8.555 8.555 0 0 0 20 4.226Z"
      fill="currentColor"
    />
  </svg>
);

export const Youtube = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 20 21"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.91 4.93c.31.312.534.701.649 1.129.66 2.678.508 6.909.012 9.69a2.537 2.537 0 0 1-.649 1.13 2.51 2.51 0 0 1-1.12.654c-1.553.426-7.8.426-7.8.426s-6.246 0-7.798-.426a2.51 2.51 0 0 1-1.121-.654 2.537 2.537 0 0 1-.649-1.13c-.664-2.666-.482-6.9-.013-9.677.115-.428.339-.817.65-1.13a2.51 2.51 0 0 1 1.12-.654c1.552-.426 7.799-.44 7.799-.44s6.246 0 7.799.427c.423.116.81.341 1.12.654Zm-5.726 5.974L8 13.928V7.88l5.183 3.023Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .904)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
