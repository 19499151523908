import React, {useEffect, useState} from 'react';
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Picture from "@/shared/ui/Picture";
import Helmet from 'react-helmet';
import { animated } from 'react-spring';
import { useBlockAnimate } from '@/shared/hooks/useBlockAnimate';
import { BASE_HOST } from '@/shared/constants/env';
import { FLAPPY_BIRD_RAVEN, GAME_ID } from "@/widgets/Landing/rvn/components/Game/constants/flappy_bird";
import s from './Game.module.scss';
import ScrollToGameRvn from "@/widgets/Landing/rvn/components/Game/components/ScrollToGameRvn";
import { useInView } from "react-intersection-observer";

const FlappyBird = dynamic(() => import('@/widgets/Landing/rvn/components/Game/components/FlappyBird'), {
  ssr: false,
  loading: () => <div style={{
    minHeight: '544px',
    backgroundColor: '#1A1A1A',
    borderRadius: '42px',
    boxShadow: '0 0 0 1px #5a5e7c',
  }} className={cx('overflow-hidden mx-auto')}/>,
});

const GameSEO = () => {
  const imageUrl = `${BASE_HOST}/assets/game/raven-game-meta-cover.png`
  return (
    <Helmet>
      <meta property="og:image" content={imageUrl} />
    </Helmet>
  );
}

const Game = () => {
  const { ref: inViewRef, inView } = useInView();

  const { query } = useRouter();
  const [game, setGame] = useState(false);
  const { ref: ref1, springStyles: springTitleStyles1 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref2, springStyles: springTitleStyles2 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const isApplyGameSEO = FLAPPY_BIRD_RAVEN.utm_source === query.utm_source;

  const handleLoadGame = () => {
    setGame(true);
  };

  return (
    <>
      <ScrollToGameRvn inView={inView}/>
      {isApplyGameSEO && <GameSEO/>}
      <div className={s.root}>
        <div className="flex flex-col md:flex-row items-center mb-12 md:mb-20">
          <div className="mr-4" ref={ref1}>
            <animated.h2 className="landing-title" style={springTitleStyles1}>
              <FormattedMessage id="flappy.game.title" values={{
                span: (...chunks) => <span className="theme-text-highlight">{chunks}</span>,
              }}/>
            </animated.h2>
          </div>
        </div>

        <animated.div className={s.gameWrapper} ref={ref2} style={springTitleStyles2}>
          <div className={s.image} id={GAME_ID} ref={inViewRef}>
            {game
              ? <FlappyBird />
              : <div className={cx(s.pictureWrapper, 'cursor-pointer')}>
                  <Picture
                    onClick={handleLoadGame}
                    size={[1028, 615]}
                    alt="Hiveon OS"
                    src="/assets/images/flappy-bird-game.png"
                    locales={['en']}
                    formats={['png']}
                  />
                  <div className={s.pictureButton}>
                    <FormattedMessage id="Start playing" />
                  </div>
              </div>
            }
          </div>
        </animated.div>
      </div>
    </>
  );
}

export default Game;
