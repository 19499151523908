import { useNavigation } from '@/processes/Router';
import ThemeButton from '@/shared/ui/ThemeButton';
import { useMinerAccount } from "@/processes/MinerAccounts";
import { loginLinkExternal } from '@/global/utils/apiHelpers';

type JoinButtonProps = {
  title: string;
  gtmButton?: string;
  gtmBlock?: string;
}

const JoinButton = (props: JoinButtonProps) => {
  const {
    title = '',
    gtmButton = '',
    gtmBlock = '',
  } = props;

  const { isAuth } = useMinerAccount();
  const { locale } = useNavigation();
  const loginLink = loginLinkExternal('https://the.hiveos.farm', locale);
  
  // for auth user hide button
  if (isAuth) { return null; }

  return (
    <div className="">
      <ThemeButton 
        component='a' 
        target="_blank" 
        href={loginLink}
        data-gtm-category="site_pool" 
        data-gtm-action="get_started_click" 
        data-gtm-label={JSON.stringify({
          button: gtmButton,
          block: gtmBlock,
        })}
      >
        {title}
      </ThemeButton>
    </div>
  );
}

export default JoinButton;
