import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import cx from "classnames";
import s from "./HowToGetHiveonOs.module.scss";
import { animated } from 'react-spring';
import { useBlockAnimate } from '@/shared/hooks/useBlockAnimate';
import { useLocalizedUrl } from '@/shared/hooks/useLocalizedUrl';
import { useNavigation } from '@/processes/Router';
import _toLower from 'lodash/toLower';
import _get from 'lodash/get';
import Picture from "@/shared/ui/Picture";
import TutorialLink from "@/entities/TutorialLink";
import VideoGuideModal from "./VideoGuideModal";
import { VIDEO_GUIDES_YOUTUBE_CODES, TUTORIAL_LINKS } from "./constants/os";

type HowToGetHiveonOsProps = {
  className?: string;
}

const HowToGetHiveonOs = (props: HowToGetHiveonOsProps) => {
  const { coin, coinTitle, isBTC, isGRAM } = useNavigation();
  const { localizeUrl } = useLocalizedUrl();
  const [show, setShow] = useState(false);
  const coinImg = _toLower(coin);
  const { id: youtubeId, start } = _get(VIDEO_GUIDES_YOUTUBE_CODES, coin, {});
  const tutorialLink = _get(TUTORIAL_LINKS, coin, 'https://hiveon.com/knowledge-base/hiveon-pool/hiveon-setting-up/');

  const { ref: ref1, springStyles: springTitleStyles1 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref2, springStyles: springTitleStyles2 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref3, springStyles: springTitleStyles3 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });

  return (
    <>
      <div className={s.root}>
        {isBTC ? <>
          <animated.h2 ref={ref1} className="landing-title my-8 sm:my-20" style={springTitleStyles1}>
            <FormattedMessage id="landing.howToGetHiveon.title" values={{
              coinTitle,
              br: <br/>,
              span: (...chunks) => <span className="theme-text-highlight">{chunks}</span>,
            }}/>
          </animated.h2>

          <animated.div ref={ref2} style={springTitleStyles2}>
            <Picture
              className={cx(s.image, s.imageShift)}
              size={[ 682, 1266 ]}
              alt="Hiveon OS"
              src={`/assets/images/${coinImg}-hive-os-mobile.png`}
              medias={[
                {
                  media: '(max-width: 768px)',
                  src: `/assets/images/${coinImg}-hive-os-mobile.png`,
                  size: [ 682, 1266 ],
                },
                {
                  media: '(min-width: 769px)',
                  src: `/assets/images/${coinImg}-hive-os.png`,
                  size: [ 1752, 1040 ],
                },
              ]}
              locales={[ 'en' ]}
              formats={[ 'png' ]}
            />
          </animated.div>
        </> : null}

        <animated.h3 ref={ref3} className={s.subTitle} style={springTitleStyles3}>
          <FormattedMessage id="landing.howToGetHiveon.subtitle" values={{
            span: (...chunks) => <span className="theme-text-highlight">{chunks}</span>,
            br: <br/>,
          }}/>
        </animated.h3>

        <ol className={s.steps}>
          <li className={cx(s.step, s.stepBg1)}>
            <span className={s.stepCounter}>1</span>
            <FormattedMessage id="landing.howToGetHiveon.step.1"/></li>
          <li className={cx(s.step, s.stepBg2)}>
            <span className={s.stepCounter}>2</span>
            <FormattedMessage id="landing.howToGetHiveon.step.2" values={{ coin }}/>
          </li>
          <li className={cx(s.step, s.stepBg3)}>
            <span className={s.stepCounter}>3</span>
            <FormattedMessage id={isBTC ? "landing.howToGetHiveon.step.btc.3" : "landing.howToGetHiveon.step.3"}/>
          </li>
        </ol>

        {!isBTC && !isGRAM ? <animated.div ref={ref2} style={springTitleStyles2}>
          <Picture
            className={cx(s.image, s.imageShift)}
            size={[ 682, 1266 ]}
            alt="Hiveon OS"
            src={`/assets/images/${coinImg}-hive-os-mobile.png`}
            medias={[
              {
                media: '(max-width: 768px)',
                src: `/assets/images/${coinImg}-hive-os-mobile.png`,
                size: [ 682, 1266 ],
              },
              {
                media: '(min-width: 769px)',
                src: `/assets/images/${coinImg}-hive-os.png`,
                size: [ 1752, 1040 ],
              },
            ]}
            locales={[ 'en' ]}
            formats={[ 'png' ]}
          />
        </animated.div> : null}

        <div className="flex justify-center text-center my-8 sm:my-20">
          <TutorialLink
            target="_blank"
            link={localizeUrl(tutorialLink)}
            data-gtm-category="site_pool"
            data-gtm-action="tutorial_click"
            data-gtm-label={JSON.stringify({
              tutorial_type: 'hive_os',
            })}
          />
          {youtubeId ? (
            <TutorialLink
              className="ml-8 sm:ml-20"
              icon="video"
              onClick={(e: any) => {
                e.preventDefault();
                setShow(true)
              }}
              target="_blank"
              link={`https://youtu.be/${youtubeId}`}
              data-gtm-category="site_pool"
              data-gtm-action="video_tutorial_click"
              data-gtm-label={JSON.stringify({
                tutorial_type: 'hive_os',
              })}
            />
          ) : null}
        </div>
      </div>

      {youtubeId ? (
        <VideoGuideModal
          youtubeId={youtubeId}
          start={start}
          show={show}
          onDismiss={() => { setShow(false) }}
          classNameContainer={s.modalContent}
          classNameCloseBtn={s.closeBtn}
        />
      ) : null}
    </>
  );
};

export default HowToGetHiveonOs;
