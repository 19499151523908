import React from "react";
import { FormattedMessage } from "react-intl";
import cx from "classnames";
import s from "./Advantages.module.scss";
import { animated } from 'react-spring';
import { useBlockAnimate } from '@/shared/hooks/useBlockAnimate';
import { useNavigation } from '@/processes/Router';

type AdvantagesProps = {
  className?: string;
}

const Advantages = (props: AdvantagesProps) => {
  const { className } = props;
  const { coin } = useNavigation();

  const { ref: ref1, springStyles: springTitleStyles1 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { ref: ref2, springStyles: springTitleStyles2 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });

  return (
    <div className={cx(s.root, className)} ref={ref1}>
      <animated.h2 className="landing-title" style={springTitleStyles1}>
        <FormattedMessage id="landing.advantages.title" values={{ coin }} />
      </animated.h2>
      <div className={s.blocks}>
        <span className={cx(s.abstract1, '-left-80 -top-36')} aria-hidden={true}/>
        <span className={cx(s.abstract1, '-right-52 -bottom-36')} aria-hidden={true}/>

        <span className={cx(s.abstract2, '-top-10 left-0 right-0 mx-auto')} aria-hidden={true}/>
        <span className={cx(s.abstract2, '-bottom-4 left-0 right-0 mx-auto transform rotate-180')} aria-hidden={true}/>
        <span className={cx(s.abstract2, '-left-44 -ml-2.5 top-0 bottom-0 my-auto transform -rotate-90')} aria-hidden={true}/>
        <span className={cx(s.abstract2, '-right-44 -mr-2.5 top-0 bottom-0 my-auto transform rotate-90')} aria-hidden={true}/>

        <animated.div ref={ref2} style={springTitleStyles2} className={s.blocksGrid}>
          <div className={s.blocksCol}>
            <div className={cx(s.blockWrap, s.blockmH160)}>
              <div className={cx(s.block)}>
                <span
                  className={cx(
                    "theme-text-highlight shadow text-3xl sm:text-5xl font-bold"
                  )}
                >
                  <FormattedMessage id="landing.advantages.percent.poolFee" values={{percent: '0,5%'}}/>{' '}
                </span>
              </div>
              <div className={cx(s.blockHover, s.blockHoverPos1)}>
                <FormattedMessage id="landing.advantages.percent.poolFee.hover" />
              </div>
            </div>

            <div className={cx(s.blockWrap, s.blockmH210)}>
              <div className={cx(s.block)}>
                <span
                  className={cx(
                    "theme-text-highlight shadow text-3xl sm:text-5xl font-bold"
                  )}
                >
                  <FormattedMessage id="landing.advantages.miningOnHiveon.title" />{' '}
                </span>
                <span className="font-semibold mt-4 text-xl sm:text-2xl">
                  <FormattedMessage id="landing.advantages.miningOnHiveon.desc" values={{ coin }} />
                </span>
              </div>
              <div className={cx(s.blockHover, s.blockHoverPos1)}>
                <FormattedMessage id="landing.advantages.miningOnHiveon.hover" values={{ coin }} />
              </div>
            </div>
          </div>

          <div className={s.blocksCol}>
            <div className={cx(s.blockWrap, s.blockmH210)}>
              <div className={cx(s.block)}>
                <span
                  className={cx(
                    "theme-text-highlight shadow text-3xl sm:text-5xl font-bold"
                  )}
                >
                  <FormattedMessage id="landing.advantages.fee2.title" />{' '}
                </span>
              </div>
              <div className={cx(s.blockHover, s.blockHoverPos1)}>
                <FormattedMessage id="landing.rvn.advantages.fee2.hover" values={{ coin }}/>
              </div>
            </div>

            <div className={cx(s.blockWrap, s.blockmH160)}>
              <div className={cx(s.block, s.blockRow, 'xl:items-center')}>
                <span
                  className={cx(
                    "theme-text-highlight shadow font-bold text-3xl sm:text-5xl"
                  )}
                >
                  <FormattedMessage id="rewardType.PPS+" />
                </span>
                <span className={cx("fmt-4 xl:mt-0 xl:ml-4 font-semibold text-xl sm:text-2xl")}>
                  <FormattedMessage id="landing.advantages.pps.desc" />
                </span>
              </div>

              <div className={cx(s.blockHover, s.blockHoverPos2)}>
                <FormattedMessage id="landing.advantages.pps.hover" values={{ coin }}/>
              </div>
            </div>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default Advantages;
