import api from '@/global/api';

// cache time
const MIN_IS_SEC = 60;
const HOUR_IS_SEC = MIN_IS_SEC * 60;

export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    // BTC
    getBTCNetworkHashrate: build.query({
      query: () => `/v1/coin/BTC`,
      keepUnusedDataFor: HOUR_IS_SEC,
    }),
  }),
});

export const {
  useGetBTCNetworkHashrateQuery,
} = extendedApi;

export const {
  getBTCNetworkHashrate,
} = extendedApi.endpoints;