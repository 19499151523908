import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import cx from "classnames";
import s from "./Support.module.scss";

type SupportProps = {
  className?: string;
}

const Support = (props: SupportProps) => {
  const {
    className
  } = props;
  const intl = useIntl();

  const [isReadMore, setIsReadMore] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div className={cx("flex items-center sm:items-start flex-col sm:flex-row", className)}>
      <div className="sm:mr-10">
        <span className="theme-support" aria-hidden={true} />
      </div>
      <div className="flex-grow-1">
        <h2 className="text-4/5xl text-center sm:text-left sm:text-8xl font-bold my-5 sm:mt-0 sm:mb-8">
          <FormattedMessage id="landing.support.title" />
        </h2>
        <p className="text-base sm:text-xl leading-7 max-w-[750px]">
          <FormattedMessage id="landing.dnx.support.desc"/>{' '}
          {!isReadMore ? <button className="text-dynex" onClick={toggleReadMore}>{intl.formatMessage({id: 'Read more'})}...</button> : null}
          <span className={cx({'hidden': !isReadMore})}>
            <FormattedMessage id="landing.dnx.support.readMore"/>
          </span>
        </p>

        <div className={cx({'hidden': !isReadMore})}>
          <div className="relative max-w-[834px] sm:my-24">
            <span className={cx(s.abstractTop, '-top-10 left-0 right-0 mx-auto')} aria-hidden={true}/>
            <span className={cx(s.abstractBottom, '-bottom-10 left-0 right-0 mx-auto')} aria-hidden={true}/>
            <span className={cx(s.abstractLeft, '-left-10 -ml-2.5 top-1/2 -translate-y-1/2')} aria-hidden={true}/>
            <span className={cx(s.abstractRight, '-right-10 -mr-2.5 top-1/2 -translate-y-1/2')} aria-hidden={true}/>

            <div className={s.blocksWrapper}>
              <div className={cx(s.blocks, 'lg:pr-[72px]')}>
                <div className={s.block}>
                  <FormattedMessage id="landing.dnx.support.block1"/>
                </div>
                <div className={cx(s.block, s.blockShort50)}>
                  <FormattedMessage id="landing.dnx.support.block2"/>
                </div>
              </div>
              <div className={cx(s.blocks, 'lg:pl-[72px]')}>
                <div className={cx(s.block, s.blockShort100)}>
                  <FormattedMessage id="landing.dnx.support.block3"/>
                </div>
                <div className={s.block}>
                  <FormattedMessage id="landing.dnx.support.block4"/>
                </div>
              </div>
            </div>
          </div>

          <p className={cx('text-base sm:text-xl leading-7 max-w-[750px]')}>
            <FormattedMessage id="landing.dnx.support.readMore2" values={{
              a: (...chunks) => (
                <a className="underline text-[#31E9E6]" href="https://discord.com/invite/CVZeZdn" target="_blank">
                  {chunks}
                </a>
              ),
            }}/>
            {' '}
            <button className="text-dynex" onClick={toggleReadMore}>{intl.formatMessage({id: 'Read less'})}</button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Support;
