import { useIntl } from 'react-intl';
import cx from 'classnames';
import FooterNavBlock from './FooterNavBlock';
import FooterSocials from './FooterSocials';
import StoreBadge from './StoreBadge';
import { FOOTER_NAV, FOOTER_SOCIALS } from './navigation';
import s from './Footer.module.scss';

const Footer = (props) => {
  const intl = useIntl();
  const currentYear = new Date().getFullYear();

  return (
    <div className={cx(s.root, 'bottom-light')}>
      <div className='inner'>
        <div className={s.navigation}>
          <div className={cx(s.navCell, s.col1)}>
            <FooterNavBlock
              className={s.navBlock}
              data={FOOTER_NAV.hiveOS}
            />
          </div>
          <div className={cx(s.navCell, s.navContentAndMobileCell, s.col2)}>
            <FooterNavBlock
              className={cx(s.navBlock, s.navContentBlock)}
              data={FOOTER_NAV.content}
            />
            <FooterNavBlock
              className={cx(s.navBlock, s.navMobileBlock)}
              data={FOOTER_NAV.mobile}
              titlClass={s.mobileBadgeTitle}
            >
              <StoreBadge type="apple" className={s.mobileBadge} locale={intl.locale} />
              <StoreBadge type="google" className={s.mobileBadge} locale={intl.locale} />
            </FooterNavBlock>
          </div>
          <div className={s.navCell}>
            <FooterNavBlock
              className={cx(s.navBlock, s.community)}
              data={FOOTER_NAV.community}
            />
          </div>
          <div className={cx(s.navCell, s.navSupportCell)}>
            <FooterNavBlock
              className={cx(s.navBlock, s.navOthersBlock)}
              data={FOOTER_NAV.other}
            />
          </div>
          <div className={cx(s.navCell, s.navSupportCell)}>
            <FooterNavBlock
              className={s.navBlock}
              data={FOOTER_NAV.support}
            />
          </div>
        </div>
        <FooterSocials socials={FOOTER_SOCIALS}/>
      </div>

      <div className={s.separator} />

      <div className="inner">
        <div className={cx(s.copy)}>© {currentYear} Hiveon</div>
      </div>
      
    </div>
  );
}

export default Footer;
