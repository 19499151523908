import dayjs from 'dayjs';
import _get from "lodash/get";
require('dayjs/locale/zh-cn');

const dateFormats = {
  default: 'MMM DD, YYYY, HH:mm',
  winter: 'MMM DD, YYYY, HH:mm',
  'd': 'HH:mm',
  'w': 'MMM DD, HH:mm',
  'm': 'MMM DD, HH:mm',
};

type formatChartDateProps = {
  date: string | Date;
  format?: string;
  locale?: string;
  dateRange?: string;
  firstDate?: string | Date | null;
  lastDate?: string | Date | null;
}

export const formatChartDate = (props: formatChartDateProps) => {
  const {
    date,
    format,
    locale = 'en',
    dateRange,
    firstDate,
    lastDate

  } = props;
  const dayjsLocale = locale === 'zh' ? 'zh-cn' : locale;
  let dateFormat;

  const startDate = dayjs(firstDate).month();
  const endDate = dayjs(lastDate).month();
  const isNewYear = startDate === 11 && endDate === 0;

  if (dateRange === 'd') {
    dateFormat = dateFormats.d;
  } else if (dateRange === 'w' || dateRange === 'm') {
    if (isNewYear) {
      dateFormat = dateFormats.winter;
    } else {
      dateFormat = dateFormats.w;
    }
  } else {
    dateFormat = typeof format === 'string' && format ? format : dateFormats.default;
  }

  return dayjs(date).locale(dayjsLocale).format(dateFormat);
};

type DataItem = {
  timestamp?: string | Date;
};

export const getBoundaryTimestamps = (date: DataItem[]) => {
  const hasDateLength = date && date.length > 0;

  const firstDate = hasDateLength ? _get(date[0], 'timestamp', null) : null;
  const lastDate = hasDateLength ? _get(date[date.length - 1], 'timestamp', null) : null;

  return {
    firstDate,
    lastDate
  }
};