export const GPU_DEVICES = {
  BTC: [
    {
      value: "antminer-t9plus",
      label: "Antminer T9+",
      hashrate: 12.4,
      power: 1280,
      fee: 2,
    },
    {
      value: "antminer-s10",
      label: "Antminer S10",
      hashrate: 14.2,
      power: 1230,
      fee: 2,
    },
    {
      value: "antminer-s9",
      label: "Antminer S9",
      hashrate: 15.2,
      power: 1330,
      fee: 2,
    },
    {
      value: "antminer-t17",
      label: "Antminer T17",
      hashrate: 40.8,
      power: 1815,
      fee: 2.8,
    },
    {
      value: "antminer-s17",
      label: "Antminer S17",
      hashrate: 53.2,
      power: 1915,
      fee: 2.8,
    },
    {
      value: "antminer-t17e",
      label: "Antminer T17E",
      hashrate: 53.7,
      power: 2960,
      fee: 2.8,
    },
    {
      value: "antminer-s17e",
      label: "Antminer S17E",
      hashrate: 65.9,
      power: 3087,
      fee: 2.8,
    },
    {
      value: "antminer-t17plus",
      label: "Antminer T17+",
      hashrate: 67,
      power: 3080,
      fee: 2.8,
    },
    {
      value: "antminer-s17plus",
      label: "Antminer S17+",
      hashrate: 72.5,
      power: 2605,
      fee: 2.8,
    },
    {
      value: "antminer-s19",
      label: "Antminer S19",
      hashrate: 95,
      power: 3250,
      fee: 2.8,
    },
    {
      value: "antminer-s19-pro",
      label: "Antminer S19 Pro",
      hashrate: 110,
      power: 3575,
      fee: 2.8,
    },
    {
      value: "antminer-s19j",
      label: "Antminer S19j",
      hashrate: 90,
      power: 3100,
      fee: 2.8,
    },
    {
      value: "antminer-s19j-pro",
      label: "Antminer S19j Pro",
      hashrate: 104,
      power: 3050,
      fee: 2.8,
    },
    {
      value: "antminer-s19j-x-88",
      label: "Antminer S19x88",
      hashrate: 87,
      power: 3200,
      fee: 2.8,
    },
  ],
  ETC: [
    {
      "value": "gpu-68",
      "label": "RX 6800",
      "hashrate": 64,
      "power": 150,
    },
    {
      "value": "gpu-380",
      "label": "Radeon 380",
      "hashrate": 19,
      "power": 150,
    },
    {
      "value": "gpu-470",
      "label": "RX 470",
      "hashrate": 26,
      "power": 120,
    },
    {
      "value": "gpu-480",
      "label": "RX 480",
      "hashrate": 30,
      "power": 140,
    },
    {
      "value": "gpu-570",
      "label": "RX 570",
      "hashrate": 27.9,
      "power": 120,
    },
    {
      "value": "gpu-580",
      "label": "RX 580",
      "hashrate": 30.5,
      "power": 130,
    },
    {
      "value": "gpu-1070",
      "label": "GTX 1070",
      "hashrate": 30,
      "power": 140,
    },
    {
      "value": "gpu-1080",
      "label": "GTX 1080",
      "hashrate": 34,
      "power": 170,
    },
    {
      "value": "gpu-1660",
      "label": "GTX 1660",
      "hashrate": 23,
      "power": 80,
    },
    {
      "value": "gpu-2060",
      "label": "RTX 2060",
      "hashrate": 30,
      "power": 120,
    },
    {
      "value": "gpu-2070",
      "label": "RTX 2070",
      "hashrate": 40,
      "power": 140,
    },
    {
      "value": "gpu-2080",
      "label": "RTX 2080",
      "hashrate": 40.1,
      "power": 160,
    },
    {
      "value": "gpu-3060",
      "label": "RTX 3060",
      "hashrate": 35,
      "power": 110,
    },
    {
      "value": "gpu-3070",
      "label": "RTX 3070",
      "hashrate": 58.1,
      "power": 130,
    },
    {
      "value": "gpu-3080",
      "label": "RTX 3080",
      "hashrate": 91.5,
      "power": 230,
    },
    {
      "value": "gpu-3090",
      "label": "RTX 3090",
      "hashrate": 114,
      "power": 320,
    },
    {
      "value": "gpu-5700",
      "label": "RX 5700",
      "hashrate": 55,
      "power": 130,
    },
    {
      "value": "gpu-1060",
      "label": "GTX 1060",
      "hashrate": 22.5,
      "power": 90,
    },
    {
      "value": "gpu-fury",
      "label": "Radeon Fury",
      "hashrate": 29,
      "power": 220,
    },
    {
      "value": "gpu-vega56",
      "label": "Vega 56",
      "hashrate": 49,
      "power": 170,
    },
    {
      "value": "gpu-vega64",
      "label": "Vega 64",
      "hashrate": 50,
      "power": 190,
    },
    {
      "value": "gpu-vii",
      "label": "Radeon VII",
      "hashrate": 93,
      "power": 200,
    },
    {
      "value": "gpu-66xt",
      "label": "RX 6600XT",
      "hashrate": 32.5,
      "power": 80,
    },
    {
      "value": "gpu-3060U",
      "label": "RTX 3060 Ultra",
      "hashrate": 48,
      "power": 110,
    },
    {
      "value": "gpu-1070Ti",
      "label": "GTX 1070Ti",
      "hashrate": 30.5,
      "power": 140,
    },
    {
      "value": "gpu-1050Ti",
      "label": "GTX 1050Ti",
      "hashrate": 13,
      "power": 80,
    },
    {
      "value": "gpu-166s",
      "label": "GTX 1660 S",
      "hashrate": 29.3,
      "power": 90,
    },
    {
      "value": "gpu-1080Ti",
      "label": "GTX 1080Ti",
      "hashrate": 49,
      "power": 180,
    },
    {
      "value": "gpu-1660Ti",
      "label": "GTX 1660Ti",
      "hashrate": 28,
      "power": 80,
    },
    {
      "value": "gpu-3060Ti",
      "label": "RTX 3060Ti",
      "hashrate": 58.1,
      "power": 130,
    },
    {
      "value": "gpu-2080Ti",
      "label": "RTX 2080Ti",
      "hashrate": 55.2,
      "power": 180,
    },
    {
      "value": "gpu-5700xt",
      "label": "RX 5700 XT",
      "hashrate": 55,
      "power": 130,
    },
    {
      "value": "gpu-5600xt",
      "label": "RX 5600 XT",
      "hashrate": 40.5,
      "power": 110,
    },
    {
      "value": "gpu-68xt",
      "label": "RX 6800 XT",
      "hashrate": 64,
      "power": 150,
    },
    {
      "value": "gpu-3070Ti",
      "label": "GTX 3070Ti",
      "hashrate": 45,
      "power": 180,
    },
    {
      "value": "gpu-69xt",
      "label": "RX 6900 XT",
      "hashrate": 64,
      "power": 150,
    },
    {
      "value": "gpu-67xt",
      "label": "RX 6700 XT",
      "hashrate": 48,
      "power": 140,
    },
    {
      "value": "gpu-3080Ti",
      "label": "RTX 3080Ti",
      "hashrate": 62,
      "power": 220,
    },
    {
      "value": "gpu-3080L",
      "label": "RTX 3080 L",
      "hashrate": 48.5,
      "power": 230,
    },
    {
      "value": "gpu-3070L",
      "label": "RTX 3070 L",
      "hashrate": 30,
      "power": 130,
    },
    {
      "value": "gpu-3060TiL",
      "label": "RTX 3060Ti L",
      "hashrate": 30,
      "power": 130,
    },
    {
      "value": "gpu-3060L",
      "label": "RTX 3060 L",
      "hashrate": 24,
      "power": 110,
    },
  ],
  RVN: [
    {
      "value": "nvvalueia-rtx-3090-ti",
      "label": "NVvalueIA RTX 3090 Ti",
      "hashrate": 66.21,
      "power": 446,
    },
    {
      "value": "nvvalueia-rtx-3090",
      "label": "NVvalueIA RTX 3090",
      "hashrate": 61.17,
      "power": 362,
    },
    {
      "value": "nvvalueia-rtx-3080-ti",
      "label": "NVvalueIA RTX 3080 Ti",
      "hashrate": 58.7,
      "power": 354,
    },
    {
      "value": "nvvalueia-rtx-3080-lhr",
      "label": "NVvalueIA RTX 3080 LHR",
      "hashrate": 49.45,
      "power": 324,
    },
    {
      "value": "nvvalueia-rtx-a5000",
      "label": "NVvalueIA RTX A5000",
      "hashrate": 42.18,
      "power": 229,
    },
    {
      "value": "nvvalueia-rtx-3080",
      "label": "NVvalueIA RTX 3080",
      "hashrate": 42.17,
      "power": 250,
    },
    {
      "value": "nvvalueia-rtx-3070-ti",
      "label": "NVvalueIA RTX 3070 Ti",
      "hashrate": 37.77,
      "power": 226,
    },
    {
      "value": "amd-rx-6900-xt",
      "label": "AMD RX 6900 XT",
      "hashrate": 33.56,
      "power": 166,
    },
    {
      "value": "amd-rx-6800",
      "label": "AMD RX 6800",
      "hashrate": 33.48,
      "power": 143,
    },
    {
      "value": "amd-rx-6800-xt",
      "label": "AMD RX 6800 XT",
      "hashrate": 32.52,
      "power": 131,
    },
    {
      "value": "nvvalueia-rtx-3070-lhr",
      "label": "NVvalueIA RTX 3070 LHR",
      "hashrate": 31.84,
      "power": 154,
    },
    {
      "value": "nvvalueia-rtx-3060-ti-lhr",
      "label": "NVvalueIA RTX 3060 Ti LHR",
      "hashrate": 31.04,
      "power": 169,
    },
    {
      "value": "nvvalueia-rtx-3070",
      "label": "NVvalueIA RTX 3070",
      "hashrate": 30.59,
      "power": 150,
    },
    {
      "value": "nvvalueia-rtx-3060-ti",
      "label": "NVvalueIA RTX 3060 Ti",
      "hashrate": 30.52,
      "power": 149,
    },
    {
      "value": "nvvalueia-rtx-a4000",
      "label": "NVvalueIA RTX A4000",
      "hashrate": 26.11,
      "power": 139,
    },
    {
      "value": "nvvalueia-rtx-2080",
      "label": "NVvalueIA RTX 2080",
      "hashrate": 25.85,
      "power": 179,
    },
    {
      "value": "nvvalueia-rtx-3060-lhr-v2",
      "label": "NVvalueIA RTX 3060 LHR v2",
      "hashrate": 24.63,
      "power": 139,
    },
    {
      "value": "nvvalueia-rtx-3060",
      "label": "NVvalueIA RTX 3060",
      "hashrate": 24.09,
      "power": 140,
    },
    {
      "value": "amd-rx-6700-xt",
      "label": "AMD RX 6700 XT",
      "hashrate": 21.97,
      "power": 103,
    },
    {
      "value": "nvvalueia-rtx-2070",
      "label": "NVvalueIA RTX 2070",
      "hashrate": 21.15,
      "power": 148,
    },
    {
      "value": "nvvalueia-gtx-1080",
      "label": "NVvalueIA GTX 1080",
      "hashrate": 18.28,
      "power": 159,
    },
    {
      "value": "nvvalueia-p104-100",
      "label": "NVvalueIA P104-100",
      "hashrate": 17.92,
      "power": 163,
    },
    {
      "value": "nvvalueia-rtx-2060",
      "label": "NVvalueIA RTX 2060",
      "hashrate": 17.76,
      "power": 125,
    },
    {
      "value": "nvvalueia-gtx-1070-ti",
      "label": "NVvalueIA GTX 1070 Ti",
      "hashrate": 16.55,
      "power": 160,
    },
    {
      "value": "amd-rx-6600-xt",
      "label": "AMD RX 6600 XT",
      "hashrate": 16.39,
      "power": 94,
    },
    {
      "value": "nvvalueia-gtx-1660-ti",
      "label": "NVvalueIA GTX 1660 Ti",
      "hashrate": 15.27,
      "power": 99,
    },
    {
      "value": "nvvalueia-rtx-a2000",
      "label": "NVvalueIA RTX A2000",
      "hashrate": 15.05,
      "power": 69,
    },
    {
      "value": "amd-rx-6600",
      "label": "AMD RX 6600",
      "hashrate": 14.73,
      "power": 80,
    },
    {
      "value": "amd-rx-470",
      "label": "AMD RX 470",
      "hashrate": 14.22,
      "power": 135,
    },
    {
      "value": "amd-rx-580",
      "label": "AMD RX 580",
      "hashrate": 13.98,
      "power": 140,
    },
    {
      "value": "amd-rx-570",
      "label": "AMD RX 570",
      "hashrate": 13.98,
      "power": 148,
    },
    {
      "value": "amd-rx-5500-xt",
      "label": "AMD RX 5500 XT",
      "hashrate": 12.11,
      "power": 81,
    },
    {
      "value": "nvvalueia-p106-100",
      "label": "NVvalueIA P106-100",
      "hashrate": 11.13,
      "power": 119,
    },
    {
      "value": "nvvalueia-gtx-1050-ti",
      "label": "NVvalueIA GTX 1050 Ti",
      "hashrate": 7.06,
      "power": 75,
    },
  ],
  KAS: [
    {
        "value": "gpu-6600",
        "label": "RX 6600",
        "hashrate": 55,
        "power": 70,
    },
    {
        "value": "gpu-68",
        "label": "RX 6800",
        "hashrate": 110,
        "power": 150,
    },
    {
        "value": "gpu-380",
        "label": "Radeon 380",
        "hashrate": 15,
        "power": 120,
    },
    {
        "value": "gpu-470",
        "label": "RX 470",
        "hashrate": 51.5,
        "power": 110,
    },
    {
        "value": "gpu-480",
        "label": "RX 480",
        "hashrate": 59,
        "power": 130,
    },
    {
        "value": "gpu-570",
        "label": "RX 570",
        "hashrate": 55.5,
        "power": 110,
    },
    {
        "value": "gpu-580",
        "label": "RX 580",
        "hashrate": 59.5,
        "power": 120,
    },
    {
        "value": "gpu-1070",
        "label": "GTX 1070",
        "hashrate": 51,
        "power": 130,
    },
    {
        "value": "gpu-1080",
        "label": "GTX 1080",
        "hashrate": 59.5,
        "power": 160,
    },
    {
        "value": "gpu-1660",
        "label": "GTX 1660",
        "hashrate": 50.5,
        "power": 90,
    },
    {
        "value": "gpu-2060",
        "label": "RTX 2060",
        "hashrate": 59,
        "power": 130,
    },
    {
        "value": "gpu-2070",
        "label": "RTX 2070",
        "hashrate": 65,
        "power": 140,
    },
    {
        "value": "gpu-2080",
        "label": "RTX 2080",
        "hashrate": 79,
        "power": 150,
    },
    {
        "value": "gpu-3060",
        "label": "RTX 3060",
        "hashrate": 115,
        "power": 110,
    },
    {
        "value": "gpu-3070",
        "label": "RTX 3070",
        "hashrate": 160,
        "power": 130,
    },
    {
        "value": "gpu-3080",
        "label": "RTX 3080",
        "hashrate": 220,
        "power": 220,
    },
    {
        "value": "gpu-3090",
        "label": "RTX 3090",
        "hashrate": 260,
        "power": 260,
    },
    {
        "value": "gpu-5700",
        "label": "RX 5700",
        "hashrate": 100,
        "power": 130,
    },
    {
        "value": "gpu-1060",
        "label": "GTX 1060",
        "hashrate": 44.5,
        "power": 90,
    },
    {
        "value": "gpu-fury",
        "label": "Radeon Fury",
        "hashrate": 54,
        "power": 200,
    },
    {
        "value": "gpu-vega56",
        "label": "Vega 56",
        "hashrate": 120,
        "power": 160,
    },
    {
        "value": "gpu-vega64",
        "label": "Vega 64",
        "hashrate": 125,
        "power": 170,
    },
    {
        "value": "gpu-vii",
        "label": "Radeon VII",
        "hashrate": 180,
        "power": 170,
    },
    {
        "value": "gpu-1070Ti",
        "label": "GTX 1070Ti",
        "hashrate": 53,
        "power": 130,
    },
    {
        "value": "gpu-1050Ti",
        "label": "GTX 1050Ti",
        "hashrate": 22,
        "power": 70,
    },
    {
        "value": "gpu-166s",
        "label": "GTX 1660 S",
        "hashrate": 57.5,
        "power": 90,
    },
    {
        "value": "gpu-1080Ti",
        "label": "GTX 1080Ti",
        "hashrate": 87.5,
        "power": 170,
    },
    {
        "value": "gpu-1660Ti",
        "label": "GTX 1660Ti",
        "hashrate": 54,
        "power": 80,
    },
    {
        "value": "gpu-3060Ti",
        "label": "RTX 3060Ti",
        "hashrate": 160,
        "power": 130,
    },
    {
        "value": "gpu-2080Ti",
        "label": "RTX 2080Ti",
        "hashrate": 108,
        "power": 160,
    },
    {
        "value": "gpu-5700xt",
        "label": "RX 5700 XT",
        "hashrate": 100,
        "power": 130,
    },
    {
        "value": "gpu-5600xt",
        "label": "RX 5600 XT",
        "hashrate": 80,
        "power": 100,
    },
    {
        "value": "gpu-55xt8",
        "label": "RX 5500 XT",
        "hashrate": 40,
        "power": 90,
    },
    {
        "value": "gpu-37Ti",
        "label": "RTX 3070Ti",
        "hashrate": 170,
        "power": 150,
    },
    {
        "value": "gpu-38Ti",
        "label": "RTX 3080Ti",
        "hashrate": 265,
        "power": 290,
    },
    {
        "value": "gpu-68xt",
        "label": "RX 6800 XT",
        "hashrate": 110,
        "power": 150,
    },
    {
        "value": "gpu-69xt",
        "label": "RX 6900 XT",
        "hashrate": 110,
        "power": 150,
    },
    {
        "value": "gpu-66xt",
        "label": "RX 6600XT",
        "hashrate": 62,
        "power": 80,
    },
    {
        "value": "gpu-67xt",
        "label": "RX 6700 XT",
        "hashrate": 90,
        "power": 110,
    },
    {
        "value": "gpu-39Ti",
        "label": "RTX 3090Ti",
        "hashrate": 270,
        "power": 285,
    },
    {
        "value": "gpu-36TiL",
        "label": "RTX 3060TiL",
        "hashrate": 135,
        "power": 120,
    },
    {
        "value": "gpu-38L",
        "label": "RTX 3080L",
        "hashrate": 220,
        "power": 230,
    },
    {
        "value": "gpu-37L",
        "label": "RTX 3070L",
        "hashrate": 135,
        "power": 110,
    },
    {
        "value": "gpu-36L",
        "label": "RTX 3060L",
        "hashrate": 115,
        "power": 130,
    },
    {
        "value": "gpu-a2",
        "label": "A 2000",
        "hashrate": 100,
        "power": 80,
    },
    {
        "value": "gpu-a4",
        "label": "A 4000",
        "hashrate": 140,
        "power": 150,
    },
    {
        "value": "gpu-a5",
        "label": "A 5000",
        "hashrate": 240,
        "power": 240,
    },
  ],
  DNX: [
    {
      "value": "gpu-6600",
      "label": "RX 6600",
      "hashrate": 55,
      "power": 70,
    },
    {
      "value": "gpu-68",
      "label": "RX 6800",
      "hashrate": 110,
      "power": 150,
    },
    {
      "value": "gpu-380",
      "label": "Radeon 380",
      "hashrate": 15,
      "power": 120,
    },
    {
      "value": "gpu-470",
      "label": "RX 470",
      "hashrate": 51.5,
      "power": 110,
    },
    {
      "value": "gpu-480",
      "label": "RX 480",
      "hashrate": 59,
      "power": 130,
    },
    {
      "value": "gpu-570",
      "label": "RX 570",
      "hashrate": 55.5,
      "power": 110,
    },
    {
      "value": "gpu-580",
      "label": "RX 580",
      "hashrate": 59.5,
      "power": 120,
    },
    {
      "value": "gpu-1070",
      "label": "GTX 1070",
      "hashrate": 51,
      "power": 130,
    },
    {
      "value": "gpu-1080",
      "label": "GTX 1080",
      "hashrate": 59.5,
      "power": 160,
    },
    {
      "value": "gpu-1660",
      "label": "GTX 1660",
      "hashrate": 50.5,
      "power": 90,
    },
    {
      "value": "gpu-2060",
      "label": "RTX 2060",
      "hashrate": 59,
      "power": 130,
    },
    {
      "value": "gpu-2070",
      "label": "RTX 2070",
      "hashrate": 65,
      "power": 140,
    },
    {
      "value": "gpu-2080",
      "label": "RTX 2080",
      "hashrate": 79,
      "power": 150,
    },
    {
      "value": "gpu-3060",
      "label": "RTX 3060",
      "hashrate": 115,
      "power": 110,
    },
    {
      "value": "gpu-3070",
      "label": "RTX 3070",
      "hashrate": 160,
      "power": 130,
    },
    {
      "value": "gpu-3080",
      "label": "RTX 3080",
      "hashrate": 220,
      "power": 220,
    },
    {
      "value": "gpu-3090",
      "label": "RTX 3090",
      "hashrate": 260,
      "power": 260,
    },
    {
      "value": "gpu-5700",
      "label": "RX 5700",
      "hashrate": 100,
      "power": 130,
    },
    {
      "value": "gpu-1060",
      "label": "GTX 1060",
      "hashrate": 44.5,
      "power": 90,
    },
    {
      "value": "gpu-fury",
      "label": "Radeon Fury",
      "hashrate": 54,
      "power": 200,
    },
    {
      "value": "gpu-vega56",
      "label": "Vega 56",
      "hashrate": 120,
      "power": 160,
    },
    {
      "value": "gpu-vega64",
      "label": "Vega 64",
      "hashrate": 125,
      "power": 170,
    },
    {
      "value": "gpu-vii",
      "label": "Radeon VII",
      "hashrate": 180,
      "power": 170,
    },
    {
      "value": "gpu-1070Ti",
      "label": "GTX 1070Ti",
      "hashrate": 53,
      "power": 130,
    },
    {
      "value": "gpu-1050Ti",
      "label": "GTX 1050Ti",
      "hashrate": 22,
      "power": 70,
    },
    {
      "value": "gpu-166s",
      "label": "GTX 1660 S",
      "hashrate": 57.5,
      "power": 90,
    },
    {
      "value": "gpu-1080Ti",
      "label": "GTX 1080Ti",
      "hashrate": 87.5,
      "power": 170,
    },
    {
      "value": "gpu-1660Ti",
      "label": "GTX 1660Ti",
      "hashrate": 54,
      "power": 80,
    },
    {
      "value": "gpu-3060Ti",
      "label": "RTX 3060Ti",
      "hashrate": 160,
      "power": 130,
    },
    {
      "value": "gpu-2080Ti",
      "label": "RTX 2080Ti",
      "hashrate": 108,
      "power": 160,
    },
    {
      "value": "gpu-5700xt",
      "label": "RX 5700 XT",
      "hashrate": 100,
      "power": 130,
    },
    {
      "value": "gpu-5600xt",
      "label": "RX 5600 XT",
      "hashrate": 80,
      "power": 100,
    },
    {
      "value": "gpu-55xt8",
      "label": "RX 5500 XT",
      "hashrate": 40,
      "power": 90,
    },
    {
      "value": "gpu-37Ti",
      "label": "RTX 3070Ti",
      "hashrate": 170,
      "power": 150,
    },
    {
      "value": "gpu-38Ti",
      "label": "RTX 3080Ti",
      "hashrate": 265,
      "power": 290,
    },
    {
      "value": "gpu-68xt",
      "label": "RX 6800 XT",
      "hashrate": 110,
      "power": 150,
    },
    {
      "value": "gpu-69xt",
      "label": "RX 6900 XT",
      "hashrate": 110,
      "power": 150,
    },
    {
      "value": "gpu-66xt",
      "label": "RX 6600XT",
      "hashrate": 62,
      "power": 80,
    },
    {
      "value": "gpu-67xt",
      "label": "RX 6700 XT",
      "hashrate": 90,
      "power": 110,
    },
    {
      "value": "gpu-39Ti",
      "label": "RTX 3090Ti",
      "hashrate": 270,
      "power": 285,
    },
    {
      "value": "gpu-36TiL",
      "label": "RTX 3060TiL",
      "hashrate": 135,
      "power": 120,
    },
    {
      "value": "gpu-38L",
      "label": "RTX 3080L",
      "hashrate": 220,
      "power": 230,
    },
    {
      "value": "gpu-37L",
      "label": "RTX 3070L",
      "hashrate": 135,
      "power": 110,
    },
    {
      "value": "gpu-36L",
      "label": "RTX 3060L",
      "hashrate": 115,
      "power": 130,
    },
    {
      "value": "gpu-a2",
      "label": "A 2000",
      "hashrate": 100,
      "power": 80,
    },
    {
      "value": "gpu-a4",
      "label": "A 4000",
      "hashrate": 140,
      "power": 150,
    },
    {
      "value": "gpu-a5",
      "label": "A 5000",
      "hashrate": 240,
      "power": 240,
    },
  ],
};

export const DEFULT_HR_UNITS = {
  BTC: 'ths',
  ETC: 'mhs',
  RVN: 'mhs',
  KAS: 'mhs',
  DNX: 'ths',
};