import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import s from "@/widgets/Landing/shared/QuickStats/QuickStats.module.scss";
import { useNavigation } from "@/processes/Router";
import { getFloatOrDecimalNumber, getFormattedPower } from "@/shared/utils/formatters";
import { useCurrencyWithExchange, useCoinsStats } from "@/processes/PoolStats";
import _get from "lodash/get";
import { toFixed } from '@/shared/utils/toFixed';
import { useGetBTCNetworkHashrateQuery } from '@/widgets/Landing/btc/components/NetworkHashrateChart';

type MiningMetricsProps = {
  className: string,
}
const QuickStats = (props: MiningMetricsProps) => {
  const {
    className,
  } = props;
  const { coinsStats } = useCoinsStats();
  const { coin } = useNavigation();
  const { formatCurrency } = useCurrencyWithExchange({ coin })
  const { data = {} } = useGetBTCNetworkHashrateQuery(null);

  const coinStats = coinsStats[coin];
  const perPower = 'GH';
  const exchangeRateUSD = toFixed(_get(coinStats, 'exchangeRates.USD', 0), 5);
  const expectedReward24H = _get(coinStats, 'expectedReward24H', 0);
  const chartDataLength = _get(data, `chart_data.length`, 0);
  const hashrate = _get(data, `chart_data.${chartDataLength - 1}.hashrate`, 0);
  const hashratePower = getFormattedPower(hashrate);
  const dailyEarnings = formatCurrency(expectedReward24H);


  return (
    <section className={className}>
      <ul className={s.list}>
        <li className={s.item}>
          <div className="sm:text-left text-center">
            <span className={s.itemLabel}><FormattedMessage id="Daily Earnings"/></span>
            <span className={cx(s.itemValue, 'theme-color')}>
              {dailyEarnings}{' '}/{' '}{perPower}
            </span>
          </div>
        </li>
        <li className={s.item}>
          <div className="sm:text-left text-center">
            <span className={s.itemLabel}>
              {coin}
                {' '}
                <span className="lowercase"><FormattedMessage id="Price"/></span>
            </span>
            <span className={cx(s.itemValue, 'theme-color')}>${exchangeRateUSD}</span>
          </div>
        </li>
        <li className={s.item}>
          <div className="sm:text-left text-center">
            <span className={s.itemLabel}><FormattedMessage id="rewardType.text"/></span>
            <span className={cx(s.itemValue, 'theme-color')}><FormattedMessage id="rewardType.PPLNS"/></span>
          </div>
        </li>
      </ul>
    </section>
  );
}


export default QuickStats;
